import { ProductTypes } from '../helpers/types';

export const helmetHomeConfig = {
    title: 'Tienda de Hardware para PC Gamer - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetProductsPageConfig = {
    title: 'Productos - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetOffersPageConfig = {
    title: 'Ofertas - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetEasyBuilderConfig = {
    type: 'easybuilder',
    title: 'Easy Builder - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetProBuilderConfig = {
    type: 'probuilder',
    title: 'Pro Builder - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetCheckoutConfig = {
    title: 'Checkout - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};

export const helmetProductsConfig = [
    {
        type: ProductTypes.CASE,
        url: 'gabinetes-pc-gamer',
        title: 'Gabinete de Pc Gamer, Gabinetes Gamers - Gamer Factory Argentina',
        description: 'Descubre nuestra selección de gabinetes de PC gamer en Gamer Factory Argentina.',
    },
    {
        type: ProductTypes.CPU,
        url: 'cpus-alto-rendimiento',
        title: 'CPUs de Alto Rendimiento - Gamer Factory Argentina',
        description: 'Encuentra las mejores CPUs de alto rendimiento para tu PC gamer en Gamer Factory Argentina.',
    },
    {
        type: ProductTypes.GPU,
        url: 'gpus',
        title: 'Placa de Video para Gaming, GPU Gaming - Gamer Factory Argentina',
        description: 'Mejora tu experiencia de juego con nuestras tarjetas gráficas en Gamer Factory Argentina.',
    },
    {
        type: ProductTypes.MOTHER,
        url: 'motherboards-gaming',
        title: 'Motherboard para Gamers, Motherboard gaming - Gamer Factory',
        description: 'Las mejores motherboards gaming para tu PC gamer están en Gamer Factory Argentina.',
    },
    {
        type: ProductTypes.POWER,
        url: 'fuentes-de-alimentacion',
        title: 'Fuente de Alimentación Gaming - Gamer Factory Argentina',
        description: 'Asegura el rendimiento de tu PC con nuestras fuentes de alimentación en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.RAM,
        url: 'memorias-ram-gaming',
        title: 'Memoria RAM para PC Gamer, Memoria RAM Gaming - Gamer Factory',
        description: 'Mejora la velocidad de tu PC con nuestras memorias RAM gaming en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.STORAGE,
        url: 'almacenamientos',
        title: 'Disco Duro Gaming - Gamer Factory Argentina',
        description: 'Almacena tus juegos con nuestros discos duros de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.CPU_COOLER,
        url: 'coolers-pc',
        title: 'Cooler para PC Gamer, Cooler para Gaming - Gamer Factory Argentina',
        description: 'Mantén tu PC fresca con nuestros coolers de alta eficiencia de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.CASE_FAN,
        url: 'ventiladores-pc',
        title: 'Ventilador para PC Gaming - Gamer Factory Argentina',
        description: 'Mejora la ventilación de tu PC con nuestros ventiladores de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.THERMAL_PASTE,
        url: 'pastas-termicas-cpu',
        title: 'Pasta Térmica para CPU Gamer - Gamer Factory Argentina',
        description: 'Asegura una óptima disipación de calor con nuestras pastas térmicas de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.ACCESSORIES,
        url: 'accesorios-gaming',
        title: 'Accesorios para Gaming - Gamer Factory Argentina',
        description: 'Completa tu setup gamer con nuestros accesorios de calidad en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.HEADSET,
        url: 'auriculares-gaming',
        title: 'Auriculares Gaming, Auriculares para Gamers - Gamer Factory',
        description: 'Sumérgete en el juego con nuestros auriculares de alta calidad de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.MICROPHONE,
        url: 'microfonos-gaming',
        title: 'Micrófonos para Gamers - Gamer Factory Argentina',
        description: 'Mejora tu comunicación con nuestros micrófonos de alta calidad en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.FURNITURE,
        url: 'mobiliarios-gaming',
        title: 'Mobiliario para Gamers - Gamer Factory Argentina',
        description: 'Equipa tu espacio de juego con nuestro mobiliario especializado de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.MONITOR,
        url: 'monitores-gaming',
        title: 'Monitor Gamer, Monitor para Gamer - Gamer Factory Argentina',
        description: 'Descubre nuestros monitores con la mejor resolución para gaming en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.MOUSE,
        url: 'mouses-gaming',
        title: 'Mouse Gaming, Mouse Gamer - Gamer Factory Argentina',
        description: 'Mejora tu precisión con nuestros mouses gaming diseñados para gamers de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.SPEAKERS,
        url: 'parlantes-gaming',
        title: 'Parlantes para Gaming, Parlantes Gamers - Gamer Factory Argentina',
        description: 'Disfruta de un sonido envolvente con nuestros parlantes gaming de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.CHAIR,
        url: 'sillas-gaming',
        title: 'Sillas Gaming, Sillas Gamers - Gamer Factory Argentina',
        description: 'Juega cómodamente con nuestras sillas gamings diseñadas para gamers de Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.KEYBOARD,
        url: 'teclados-gaming',
        title: 'Teclado para Gaming, Teclado para Gamers - Gamer Factory Argentina',
        description: 'Encuentra el teclado gaming perfecto para tus sesiones de juego en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.BUILD,
        url: 'pcs-escritorio-gaming',
        title: 'PCs de Escritorio Gaming, PC de escritorio Gamer - Gamer Factory',
        description: 'Descubre nuestras PCs de escritorio gaming diseñadas para el gaming en Gamer Factory, Argentina.',
    },
    {
        type: ProductTypes.NOTEBOOKS,
        url: 'notebooks-gaming',
        title: 'Notebooks para Gaming, Notebook - Gamer Factory Argentina',
        description: 'Encuentra las mejores notebooks para gaming en Gamer Factory, Argentina.',
    },
];

export const helmetCompleteOrderConfig = {
    title: 'Completa tu orden - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};

export const helmetFAQConfig = {
    title: 'FAQ - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetPromosConfig = {
    title: 'Promos - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetRegretPolicyConfig = {
    title: 'Política de devolución - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetDeleteAccountConfig = {
    title: 'Eliminar cuenta - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetUserProfileConfig = {
    title: 'Mi cuenta - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};

export const helmetUserEditDataConfig = {
    title: 'Editar cuenta - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};
