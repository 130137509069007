import { Box } from '@material-ui/core';

import { StyledTitle, StyledTypography } from '../components/TextBlock';
import { regretConditions, regretMoneyConditions } from './constants';

const FaqRegretPolicy = () => (
    <div id="faq-regret-policy">
        <StyledTitle>Políticas de Devolución y Arrepentimiento</StyledTitle>
        <Box sx={{ ml: '20px' }}>
            <StyledTitle>Derecho de Arrepentimiento:</StyledTitle>
            <StyledTypography>
                De acuerdo con la normativa vigente, tenés derecho a arrepentirte de tu compra dentro de los 10 días corridos desde la recepción del producto. Para ejercer este derecho,
                debés notificar tu decisión a través del&nbsp;
                <strong>Botón de Arrepentimiento</strong>
                &nbsp;en nuestro sitio web. Este derecho es aplicable exclusivamente a compras realizadas de manera online o a distancia.
            </StyledTypography>
            <ol>
                <li>
                    <strong>Solicitud de Arrepentimiento:</strong>
                    &nbsp;Podés solicitar la devolución del producto enviando los datos requeridos al mail correspondiente a través del
                    <strong>Botón de Arrepentimiento</strong>
                    &nbsp;en nuestra página principal.
                </li>
                <li>
                    <strong>Condiciones para la Devolución:</strong>
                    <ul>
                        {regretConditions.map((condition) => (
                            <li key={condition}>{condition}</li>
                        ))}
                    </ul>
                </li>
                <li>
                    <strong>Devolución del Dinero:</strong>
                    <ul>
                        {regretMoneyConditions.map((condition) => (
                            <li key={condition}>{condition}</li>
                        ))}
                    </ul>
                </li>
            </ol>
            <StyledTitle>Excepciones:</StyledTitle>
            <StyledTypography>
                El derecho de arrepentimiento no aplica para productos que hayan sido usados, abiertos (en el caso de productos sellados) o que hayan sido confeccionados a medida o
                personalizados para el cliente.
            </StyledTypography>
            <StyledTitle>Botón de Arrepentimiento:</StyledTitle>
            <StyledTypography>
                El&nbsp;
                <strong>Botón de Arrepentimiento</strong>
                &nbsp;se encuentra visible y accesible en todo momento en la parte inferior de nuestra página principal. Haciendo clic en el botón, podrás acceder a las instrucciones para
                gestionar tu solicitud de devolución o arrepentimiento, conforme a lo establecido por la ley.
            </StyledTypography>
            <StyledTypography>
                <strong>Importante:</strong>
                &nbsp;El derecho de arrepentimiento es válido únicamente para compras realizadas a través de nuestra tienda online con entrega a domicilio o en puntos de retiro. No aplica
                para productos retirados de forma presencial en nuestras sucursales o depósitos.
            </StyledTypography>
        </Box>
    </div>
);

export default FaqRegretPolicy;
