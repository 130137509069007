import boolean from './boolean';

const keyboardTypes = [
    { id: 'mechanical', name: 'Mecánico' },
    { id: 'membrane', name: 'Membrana' },
    { id: 'hybrid', name: 'Hibrido' },
];

const keyboardSwitches = [
    { id: 'red', name: 'Red' },
    { id: 'brown', name: 'Brown' },
    { id: 'blue', name: 'Blue' },
    { id: 'black', name: 'Black' },
    { id: 'green', name: 'Green' },
    { id: 'silver', name: 'Silver' },
];

const keyboardBacklights = [
    { id: 'argb', name: 'ARGB' },
    { id: 'rgb', name: 'RGB' },
    { id: 'fixedColor', name: 'Color Fijo' },
    { id: 'noBacklight', name: 'Sin retroiluminación' },
];

const keyboardConnectivities = [
    { id: 'wired', name: 'Cableado' },
    { id: 'wireless', name: 'Inalámbrico' },
];

const keyboardSizes = [
    { id: 'fullSize', name: 'Full Size' },
    { id: 'tkl', name: 'TKL' },
    { id: '60', name: 'Compacto 60%' },
    { id: '65', name: 'Compacto 65%' },
    { id: '70', name: 'Compacto 70%' },
    { id: 'others', name: 'Otros' },
];

export default {
    type: keyboardTypes,
    switch: keyboardSwitches,
    backlight: keyboardBacklights,
    connectivity: keyboardConnectivities,
    programmableKeys: boolean,
    size: keyboardSizes,
};
