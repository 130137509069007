import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';

import API from '../../api/API';
import { SHIPPING_TYPES } from '../../common/constants';
import { UserContext } from '../../contexts';
import { isSubsidizedShippingPrice } from '../../data/constants';
import cartHelper from '../../helpers/cartHelper';
import shippingDataHelper from '../../helpers/shippingDataHelper';
import colors from '../../theme/colors';
import ProductShippingQuoteModal from './ProductShippingQuoteModal';
import SpecialPricePopover from './SpecialPricePopover';
import WithoutStockBanner from './WithoutStockBanner';
import Ahora6Logo from './productIcons/ahora6_icon.svg';
import delivery from './productIcons/delivery.svg';
import warranty from './productIcons/warranty.svg';
import { productHasStock } from './utils';

const useStyles = makeStyles(() => ({
    withBorder: {
        borderBottomColor: '#F8E837',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
    },
    detailsText: {
        color: '#727272',
        fontWeight: 300,
    },
    productText: {
        fontSize: '20px',
        letterSpacing: 0.5,
        lineHeight: '1.43',
    },
    detailsBox: {
        borderBottom: '1px solid #DBDDB5',
    },
    iconBox: {
        minWidth: 38,
    },
    iconStyle: {
        paddingRight: 16,
    },
    strikethroughPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        color: colors.blackGrey,
        fontSize: '18px',
        fontWeight: 400,
    },
    agencyNotFoundButton: {
        cursor: 'pointer',
        color: colors.black,
        paddingTop: '5px',
    },
    priceSpecial: {
        color: colors.blackGrey,
        fontWeight: 400,
        lineHeight: '38px',
        marginLeft: '8px',
    },
    installmentsText: {
        fontWeight: 300,
        fontSize: '21px',
        color: colors.blackGrey,
        lineHeight: '27px',
    },
    highlightedFreeShippingContainer: {
        display: 'inline',
        '@media (max-width: 1080px)': {
            display: 'flex',
            marginBottom: '-17px',
        },
    },
    highlightedFreeShiping: {
        padding: '5px 8px 3px 0px',
        color: colors.green,
        fontWeight: 700,
        display: 'inline',
    },
}));

const ProductInfo = ({ item }) => {
    const classes = useStyles();
    const [state] = useContext(UserContext);
    const productId = item.id;
    const [loadingShippingPrice, setLoadingShippingPrice] = useState(false);
    const [openProductShippingQuoteModal, setOpenProductShippingQuoteModal] = useState(false);
    const [shippingData, setShippingData] = shippingDataHelper.usePersistedData('shippingData');
    const [errorQuotingPrice, setErrorQuotingPrice] = useState(false);
    const [initialShippingDataForAgencyChange, setInitialShippingDataForAgencyChange] = useState({});

    const checkIfThereIsAPreviousQuote = (data) => data.province && data.locality && data.price;

    useEffect(() => {
        async function setUserLocalityBasedOnProfile(data) {
            setLoadingShippingPrice(true);
            const userLocalityFromSettingsAsObject = await shippingDataHelper.formatUserPredefinedLocalityToObject(data?.locality);

            const shippingDataFromUserSettings = {
                ...data,
                codigoPostal: userLocalityFromSettingsAsObject?.codigoPostal,
                locality: userLocalityFromSettingsAsObject,
                province: shippingDataHelper.formatUserPredefinedProvinceToObject(data?.province),
                shippingType: SHIPPING_TYPES.homeDelivery,
            };
            const thereIsShippingDataToQuote = shippingDataFromUserSettings.codigoPostal && shippingDataFromUserSettings.province && shippingDataFromUserSettings.locality;

            if (thereIsShippingDataToQuote) {
                API.shipping
                    .quote({ productId, shippingData: shippingDataFromUserSettings })
                    .then((response) => {
                        setErrorQuotingPrice(false);
                        setShippingData({
                            ...shippingDataFromUserSettings,
                            price: response.data.price,
                        });
                    })
                    .catch(() => setErrorQuotingPrice(true))
                    .finally(() => setLoadingShippingPrice(false));
            } else {
                setLoadingShippingPrice(false);
                setErrorQuotingPrice(true);
            }
        }

        if (
            productId &&
            state.user?.shippingData &&
            (checkIfThereIsAPreviousQuote(shippingData) === null || checkIfThereIsAPreviousQuote(shippingData) === undefined) &&
            !errorQuotingPrice
        ) {
            setUserLocalityBasedOnProfile(state.user?.shippingData);
        }
    }, [errorQuotingPrice, productId, setShippingData, shippingData, state.user?.shippingData]);

    const toggleProductShippingQuoteModal = (isOpen = null) => {
        if (isOpen !== null) {
            return setOpenProductShippingQuoteModal(isOpen);
        }
        return setOpenProductShippingQuoteModal(!openProductShippingQuoteModal);
    };

    return (
        <>
            <Box pr={2} display="flex" className={classes.withBorder}>
                <Typography variant="h1" align="left" className={classes.productText}>
                    {item.name.toUpperCase()}
                </Typography>
            </Box>
            <Box mt={1} mb={2} display="flex">
                <Typography variant="subtitle2" className={classes.detailsText}>
                    Código del producto: {item.code}
                </Typography>
            </Box>
            <Box pb={2} display="flex" alignItems="baseline" className={classes.detailsBox}>
                <Box display="flex" justifyContent="center" className={classes.iconBox}>
                    <img alt="product" src={delivery} className={classes.iconStyle} />
                </Box>
                {loadingShippingPrice ? (
                    <CircularProgress size={18} />
                ) : (
                    <Box className={classes.detailsText} textAlign="left">
                        {shippingData.price !== null && shippingData.locality ? (
                            <>
                                {isSubsidizedShippingPrice ? (
                                    <Box className={classes.highlightedFreeShippingContainer}>
                                        <Typography variant="subtitle1" className={classes.highlightedFreeShiping}>
                                            ENVÍO GRATIS
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="subtitle1" display="inline">
                                        Llega por{' '}
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                display: 'inline',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {cartHelper.formatPrice(shippingData.price)}
                                        </Typography>{' '}
                                        a {shippingData.locality.name}
                                    </Typography>
                                )}
                            </>
                        ) : (
                            <>
                                {isSubsidizedShippingPrice ? (
                                    <Box className={classes.highlightedFreeShippingContainer}>
                                        <Typography variant="subtitle1" className={classes.highlightedFreeShiping}>
                                            ENVÍO GRATIS
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="subtitle1" display="inline">
                                        Envío a todo el país
                                    </Typography>
                                )}
                            </>
                        )}
                        <br />
                        <Typography
                            style={{ fontSize: 12, fontWeight: 700 }}
                            onClick={() => {
                                toggleProductShippingQuoteModal();
                                setInitialShippingDataForAgencyChange(shippingData);
                            }}
                            className={classes.agencyNotFoundButton}
                        >
                            {shippingData.price && shippingData.locality ? 'CAMBIAR DIRECCIÓN' : 'CALCULAR COSTO DE ENVÍO'}
                        </Typography>
                    </Box>
                )}
                <ProductShippingQuoteModal
                    openProductShippingQuoteModal={openProductShippingQuoteModal}
                    toggleProductShippingQuoteModal={toggleProductShippingQuoteModal}
                    shippingData={shippingData}
                    setShippingData={setShippingData}
                    productId={productId}
                    initialShippingDataForAgencyChange={initialShippingDataForAgencyChange}
                />
            </Box>
            {item.warranty && (
                <Box mt={3} pb={3} display="flex" className={productHasStock(item) ? classes.detailsBox : ''}>
                    <Box display="flex" justifyContent="center" className={classes.iconBox}>
                        <img alt="product" src={warranty} className={classes.iconStyle} />
                    </Box>
                    <Typography variant="subtitle1" align="left" className={classes.detailsText}>
                        Garantía <b>{item.warranty}</b>
                    </Typography>
                </Box>
            )}
            {!productHasStock(item) ? (
                <WithoutStockBanner />
            ) : (
                <>
                    <Box className={classes.detailsBox} mt={1} pb={1}>
                        {item.price.special.strikethrough && (
                            <Typography variant="h5" className={classes.strikethroughPrice}>
                                {cartHelper.formatPrice(item.price.special.strikethrough)}
                            </Typography>
                        )}
                        <Box display="flex" alignItems="baseline">
                            <Typography variant="h2" align="left" style={{ fontWeight: 700, fontSize: '32px', lineHeight: '38px' }}>
                                {cartHelper.formatPrice(item.price.special.ARS ?? 0)}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.priceSpecial}>
                                Precio especial
                            </Typography>
                            <SpecialPricePopover />
                        </Box>
                    </Box>
                    <Box style={{ marginTop: '16px', display: 'flex', flexDirection: 'row' }}>
                        <Box>
                            <Typography variant="h2" align="left" className={classes.installmentsText}>
                                6 cuotas sin interés de <strong>{cartHelper.formatPrice((item.price.list.ARS ?? 0) / 6)}</strong>
                            </Typography>
                            <Typography
                                variant="h4"
                                align="left"
                                style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: colors.blackGrey,
                                    marginTop: '4px',
                                }}
                            >
                                <Typography variant="h4" style={{ fontWeight: 700, color: colors.black, display: 'inline-block' }}>
                                    {cartHelper.formatPrice(item.price.list.ARS ?? 0)}
                                </Typography>{' '}
                                Precio de lista
                            </Typography>
                        </Box>
                        <Box ml={4}>
                            <img src={Ahora6Logo} alt="Ahora 6" width={32} height={48} />
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

export default ProductInfo;
