import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Facebook as FacebookIcon, Instagram as InstagramIcon, WhatsApp as WhatsAppIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import { EMAIL } from './common/constants';

const useStyles = makeStyles((theme) => ({
    footer: {
        background: '#1C1C1C',
        color: '#F8F9FB !important',
        fontSize: '12px',
        minHeight: '170px',
        padding: '30px 0',
        textAlign: 'left',
    },
    footerGrid: {
        [theme.breakpoints.up('md')]: {
            '&>div:not(:last-child)': {
                borderRight: '1px solid #404040',
            },
        },
        [theme.breakpoints.down('sm')]: {
            '&>div:not(:last-child)': {
                marginBottom: '12px',
            },
        },
    },
    footerGridItem: {
        margin: 'auto',
        maxWidth: '205px',
    },
    footerGridItemBorderBottom: {
        [theme.breakpoints.down('sm')]: {
            borderBottom: '1px solid #404040',
            paddingBottom: '12px',
        },
    },
    footerGridItemCenteredText: {
        textAlign: 'center',
    },
    socialIcon: {
        marginRight: '5px',
        width: '21px',
    },
    yellowLink: {
        color: '#F8E837;',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    footerTitle: {
        fontWeight: 700,
        marginBottom: 12,
    },
}));

export default function GDFooter() {
    const classes = useStyles();

    const WHATSAPP_NUMBER = '11-8031-0399';
    const BUSINESS_HOURS = 'Lun. a Vie. de 10 a 18h.';
    const SOCIAL_MEDIA = [
        {
            key: 'Facebook',
            url: 'https://www.facebook.com/gamerfactory.arg/',
            icon: <FacebookIcon className={classes.socialIcon} />,
        },
        {
            key: 'Instagram',
            url: 'https://instagram.com/gamerfactory.ar',
            icon: <InstagramIcon className={classes.socialIcon} />,
        },
    ];

    return (
        <footer className={classes.footer}>
            <Container maxWidth="xl">
                <Grid container className={classes.footerGrid} justifyContent="center">
                    <Grid item xs={12} md={4}>
                        <Box className={`${classes.footerGridItem} ${classes.footerGridItemBorderBottom} ${classes.footerGridItemCenteredText}`}>
                            <Typography variant="subtitle2" className={classes.footerTitle}>
                                Atención al cliente
                            </Typography>
                            <Link href={`mailto:${EMAIL}`}>
                                <Typography variant="subtitle2" style={{ color: '#F8F9FB', marginBottom: '5px' }}>
                                    {EMAIL}
                                </Typography>
                            </Link>
                            <Link href="https://wa.me/+5491180310399" target="_blank">
                                <Typography
                                    variant="subtitle2"
                                    style={{
                                        color: '#F8F9FB',
                                        marginBottom: '5px',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <WhatsAppIcon />
                                    {WHATSAPP_NUMBER}
                                </Typography>
                            </Link>
                            <Typography variant="subtitle2" style={{ color: '#F8F9FB', marginBottom: '5px' }}>
                                {BUSINESS_HOURS}
                            </Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <Box className={`${classes.footerGridItem} ${classes.footerGridItemBorderBottom} ${classes.footerGridItemCenteredText}`}>
                            <Typography variant="subtitle2" className={classes.footerTitle}>
                                Comunidad GF
                            </Typography>
                            <Typography variant="subtitle2" style={{ marginBottom: 12 }}>
                                Aclará todas tus dudas para armar el mejor build gamer
                            </Typography>
                            <Link className={classes.yellowLink} href={process.env.REACT_APP_COMUNIDAD_URL} target="_blank">
                                <Typography style={{ fontWeight: 700 }} variant="subtitle2">¡Entrá acá!</Typography>
                            </Link>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} md={4}>
                        <Box className={`${classes.footerGridItem} ${classes.footerGridItemCenteredText}`}>
                            <Box mb={1}>
                                <RouterLink className={classes.yellowLink} to="/nosotros">
                                    <Typography style={{ fontWeight: 700, marginBottom: '6px' }} variant="subtitle2">
                                        Preguntas frecuentes
                                    </Typography>
                                </RouterLink>
                                <RouterLink className={classes.yellowLink} to="/promos">
                                    <Typography style={{ fontWeight: 700, marginBottom: '6px' }} variant="subtitle2">
                                        Promociones
                                    </Typography>
                                </RouterLink>
                                <RouterLink className={classes.yellowLink} to="/politicas-de-devolucion">
                                    <Typography style={{ fontWeight: 700, marginBottom: '6px' }} variant="subtitle2">
                                        Botón de arrepentimiento
                                    </Typography>
                                </RouterLink>
                                <RouterLink className={classes.yellowLink} to="/eliminar-cuenta">
                                    <Typography style={{ fontWeight: 700, marginBottom: '6px' }} variant="subtitle2">
                                        Eliminar cuenta
                                    </Typography>
                                </RouterLink>
                            </Box>
                            <Typography variant="subtitle2" className={classes.footerTitle}>
                                ¡Seguinos!
                            </Typography>
                            {SOCIAL_MEDIA.map((item) => (
                                <Link className={classes.yellowLink} href={item.url} key={item.key} target="_blank">
                                    {item.icon}
                                </Link>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}
