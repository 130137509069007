import { Box, Container, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';

import { EMAIL } from '../../common/constants';
import { helmetRegretPolicyConfig } from '../../data/seo';
import colors from '../../theme/colors';
import TextBlock, { StyledTypography } from '../components/TextBlock';
import { mailContent } from './constants';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 237px)',
        '@media (max-width: 959px)': {
            minHeight: 'calc(100vh - 420px)',
        },
        background: colors.black,
        color: colors.white,
    },
    container: {
        paddingTop: 24,
        paddingBottom: 24,
    },
    link: {
        color: colors.yellow,
        fontWeight: 600,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
}));

const RegretPolicy = () => {
    const classes = useStyles();
    return (
        <>
            <Helmet>
                <title>{helmetRegretPolicyConfig.title}</title>
                <meta name="description" content={helmetRegretPolicyConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetRegretPolicyConfig.title} />
                <meta property="og:description" content={helmetRegretPolicyConfig.description} />
            </Helmet>
            <Box className={classes.root}>
                <Container maxWidth="xl" className={classes.container}>
                    <Typography
                        variant="h1"
                        align="center"
                        style={{
                            marginBottom: '12px',
                            color: colors.white,
                            fontSize: '20px',
                            lineHeight: '24px',
                        }}
                    >
                        Botón de Arrepentimiento
                    </Typography>

                    <StyledTypography>
                        Para ejercer tu derecho de arrepentimiento y devolver un producto dentro de los 10 días corridos desde la recepción, enviá un correo electrónico a&nbsp;
                        <Link href={`mailto:${EMAIL}`}>
                            <Typography variant="span" className={classes.link}>
                                {EMAIL}
                            </Typography>
                        </Link>
                        &nbsp;con los siguientes datos:
                    </StyledTypography>
                    <br />
                    <StyledTypography>
                        <strong>Asunto del correo:</strong>
                        &nbsp;Solicitud de Arrepentimiento - [Número de Pedido]
                    </StyledTypography>
                    <TextBlock blockTitle="Cuerpo del correo:" blockTexts={mailContent} textStrong />
                    <StyledTypography>
                        Una vez recibida tu solicitud, nos pondremos en contacto para coordinar el proceso de devolución y reembolso conforme a nuestras políticas.
                    </StyledTypography>
                    <br />
                    <StyledTypography>
                        Podés consultar más detalles sobre nuestras políticas de devolución haciendo clic&nbsp;
                        <RouterLink className={classes.link} to="/nosotros#faq-regret-policy">
                            AQUÍ
                        </RouterLink>
                    </StyledTypography>
                    <br />
                    <StyledTypography>
                        <strong>Importante:</strong>
                        &nbsp;El derecho de arrepentimiento es válido únicamente para compras realizadas a través de nuestra tienda online con entrega a domicilio o en puntos de retiro. No
                        aplica para productos retirados de forma presencial en nuestras sucursales o depósitos.
                    </StyledTypography>
                </Container>
            </Box>
        </>
    );
};

export default RegretPolicy;
