import boolean from './boolean';

const panelTypes = [
    { id: 'ips', name: 'IPS' },
    { id: 'tn', name: 'TN' },
    { id: 'va', name: 'VA' },
];

const refreshRates = [
    { id: '60hz', name: '60Hz' },
    { id: '75hz', name: '75Hz' },
    { id: '120hz', name: '120Hz' },
    { id: '144hz', name: '144Hz' },
    { id: '165hz', name: '165Hz' },
    { id: '240hz', name: '240Hz' },
    { id: '300hz', name: '300Hz' },
    { id: '360hz', name: '360Hz' },
];

const resolutions = [
    { id: 'fullHd', name: 'FULL HD' },
    { id: '2k', name: '2K' },
    { id: '4k', name: '4K' },
];

const responseTimes = [
    { id: '1ms', name: '1ms' },
    { id: '5ms', name: '5ms' },
];

const vesaOptions = [
    { id: '100x100', name: '100x100' },
    { id: '75x75', name: '75x75' },
];

export default {
    amdFreeSync: boolean,
    curvedScreen: boolean,
    nvidiaGSync: boolean,
    panelType: panelTypes,
    refresh_rate: refreshRates, // FIXME: cambiar de snake_case a camelCase
    resolution: resolutions,
    responseTime: responseTimes,
    vesa: vesaOptions,
};
