import { Box, Grid } from '@material-ui/core';

import DescriptionProduct from './DescriptionProduct';
import SpecsProduct from './SpecsProduct';

const ExtendedProductInfo = ({ product }) => {
    const specs = {
        ...product.specs,
        brand: product.brand,
    };

    return (
        <Box py={2} flexGrow={1}>
            <Grid container>
                <Grid item xs={12}>
                    <DescriptionProduct product={product} />
                    <SpecsProduct specs={specs} productType={product.type} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ExtendedProductInfo;
