const SORT_VALUES = {
    popularity: 'popularity',
    higherPrice: 'higherPrice',
    lowerPrice: 'lowerPrice',
};

const sortOptions = {
    [SORT_VALUES.popularity]: { field: 'salesQuantity', order: 'DESC' },
    [SORT_VALUES.higherPrice]: { field: 'price.special.ARS', order: 'DESC' },
};

const createSortObject = (sortValue) => sortOptions[sortValue] || { field: 'price.special.ARS', order: 'ASC' };

const productHelper = {
    createSortObject,
    SORT_VALUES,
};

export default productHelper;
