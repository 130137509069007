import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import API from '../../api/API';
import { UserContext } from '../../contexts';
import { pcGamer } from '../../data/buildTypes';
import componentsTypes from '../../data/componentsTypes';
import peripheralTypes from '../../data/peripheralTypes';
import specs from '../../data/specs';
import { sendPageViewGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import productHelper from '../../helpers/productHelper';
import { ProductTypes } from '../../helpers/types';
import useWindowDimensions from '../../helpers/useWindowsDimentions';
import { maxAndMinState } from './constants';
import { getChipsForPrice, getInitialSort, getRoutePath, getSelectedType, handleArraysForBrands, handleArraysForSpecs, prepareFilters } from './utils';

const getProductsPerPage = (width) => {
    if (width < 1280) {
        return 12;
    }
    if (width < 1920) {
        return 16;
    }
    if (width < 2880) {
        return 24;
    }
    return 36;
};

const useProductsPage = (pageRoute) => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState(null);
    const [filterQ, setFilterQ] = useState(null);
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    const [currentPage, setCurrentPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [perPage, setPerPage] = useState(null);

    const params = useParams();

    const { width } = useWindowDimensions();
    const [selectedType, setSelectedType] = useState(getSelectedType(params));
    const [price, setPrice] = useState(maxAndMinState);
    const [selectedPrice, setSelectedPrice] = useState(maxAndMinState);
    const [selectedSpecs, setSelectedSpecs] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState(null);
    const [sort, setSort] = useState(getInitialSort(query));
    const [checked, setChecked] = useState({});
    const [, dispatch] = useContext(UserContext);

    const brandQueryParamAsArray = query.get('brand') ? query.get('brand').split(',') : [];
    const minQueryParam = query.get('min');
    const maxQueryParam = query.get('max');

    const isValidPriceMinOrMaxFromQuery = (p) => (query.has(p) && !selectedPrice) || (query.has(p) && selectedPrice?.p !== query.get(p));

    useEffect(() => {
        setPerPage(getProductsPerPage(width));
        if (width >= 960) {
            setShowMobileFilters(false);
        }
    }, [width]);

    useEffect(() => {
        const selectedType = getSelectedType(params);
        setSelectedType(selectedType);
        if (params.type && !selectedType) history.push('/not-found');
    }, [params]);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    const handleSelectWithoutType = (spec) => {
        if (spec.includes('$')) {
            setPrice(maxAndMinState);
        } else if (selectedBrands?.brand.includes(spec)) {
            Object.entries(selectedBrands).forEach((selectedBrand) => {
                if (selectedBrand[1].includes(spec)) {
                    setSelectedBrands({
                        ...selectedBrands,
                        [selectedBrand[0]]: handleArraysForBrands({
                            newBrand: spec,
                            selectedBrands,
                        }),
                    });
                }
            });
        } else {
            Object.entries(selectedSpecs).forEach((selectedSpec) => {
                if (selectedSpec[1].includes(spec)) {
                    setSelectedSpecs({
                        ...selectedSpecs,
                        [selectedSpec[0]]: handleArraysForSpecs({
                            newSpec: spec,
                            specType: selectedSpec[0],
                            selectedSpecs,
                        }),
                    });
                }
            });
        }
    };

    const handleDeleteChip = (e, key) => {
        handleSelectWithoutType(key);
        setChecked({
            ...checked,
            [key]: e.target.checked === true ? false : e.target.checked,
        });
    };

    const handleChangePrice = (e, key) =>
        setSelectedPrice({
            ...selectedPrice,
            [key]: e.target.value === '' ? null : e.target.value,
        });

    const handleChangeCheck = (e) => {
        setChecked({
            ...checked,
            [e.target.name]: e.target.checked === true,
        });
    };

    const handlePriceEntered = () => {
        setPrice(selectedPrice);
        const chipsForPrice = getChipsForPrice(selectedPrice);
        setChecked({ ...checked, ...chipsForPrice });
        setSelectedPrice(maxAndMinState);
        setShowMobileFilters(false);
    };

    const handleSelect = ({ brand, spec, specType, deleteAll = false }) => {
        if (deleteAll) {
            setSelectedType(null);
            setSelectedSpecs(null);
            setSelectedBrands(null);
        } else {
            if (brand) {
                setSelectedBrands({
                    ...selectedBrands,
                    brand: handleArraysForBrands({ newBrand: brand, selectedBrands }),
                });
            }
            if (spec) {
                setSelectedSpecs({
                    ...selectedSpecs,
                    [specType]: handleArraysForSpecs({ newSpec: spec, specType, selectedSpecs }),
                });
            }
        }
    };

    const shouldResetSelectedFilters = () => query.has('q') && filterQ !== query.get('q') && !query.has('type') && selectedType;

    const shouldUpdateSelectedFilters = () =>
        (query.has('type') && !selectedType) || (query.has('type') && selectedType && selectedType.type !== query.get('type')) || (query.has('q') && filterQ !== query.get('q'));

    const resetSelectedFilters = () => {
        setSelectedType(null);
        setSelectedBrands(null);
        setSelectedPrice(maxAndMinState);
        setPrice(maxAndMinState);
        setChecked({});
    };

    const handleQFromQuery = () => {
        if (query.get('q')) setFilterQ(query.get('q'));
    };

    const checkIfTypeIsWrong = (type) => {
        if (!Object.values(ProductTypes).find((productType) => productType === type)) {
            history.push(getRoutePath({ params, pageRoute }));
        }
    };

    const handleSelectedTypeFromQuery = () => {
        const queryType = query.get('type');
        checkIfTypeIsWrong(queryType);

        switch (queryType) {
            case 'build':
                setSelectedType(pcGamer);
                break;
            case 'all':
                setSelectedType(null);
                history.push(getRoutePath({ params, pageRoute }));
                break;
            default: {
                const peripheralSelected = peripheralTypes.find((peripheral) => peripheral.type === queryType);
                if (peripheralSelected) {
                    setSelectedType(peripheralSelected);
                    break;
                }

                const flattedComponentTypes = componentsTypes.flatMap((component) => {
                    if (component.subTypes) {
                        return component.subTypes.map((subComponent) => ({ ...component, ...subComponent }));
                    }
                    return component;
                });
                const componentSelected = flattedComponentTypes.find((component) => component.type === queryType);
                setSelectedType(componentSelected);
                break;
            }
        }
    };

    const handleSelectedBrandsFromQuery = () => {
        setSelectedBrands({ brand: brandQueryParamAsArray });
    };

    const getPriceFromQuery = () => {
        const priceFromQuery = maxAndMinState;
        if (isValidPriceMinOrMaxFromQuery('min')) priceFromQuery.min = minQueryParam;
        if (isValidPriceMinOrMaxFromQuery('max')) priceFromQuery.max = maxQueryParam;
        return priceFromQuery;
    };

    const handleSelectedPriceBasedOnQuery = () => {
        if (Number.isNaN(Number(minQueryParam, 10)) || Number.isNaN(Number(maxQueryParam, 10))) {
            history.push(getRoutePath({ params, pageRoute }));
            return;
        }
        const priceFromQuery = getPriceFromQuery();
        setPrice(priceFromQuery);
        setSelectedPrice(priceFromQuery);
    };

    const getValidSpecsForFilteringFromQuery = () => {
        const querySpecs = specs[query.get('type')];
        if (!querySpecs) return [];
        return Object.values(querySpecs.specs).filter(({ values }) => values.length !== 0);
    };

    const validSpecsForFiltering = getValidSpecsForFilteringFromQuery();

    const handleSelectedSpecsFromQuery = () => {
        const specToSet = {};
        validSpecsForFiltering.forEach(({ type }) => {
            if (query.has(type)) {
                const specValues = query.get(type).split(',').filter(Boolean);
                specToSet[type] = specValues.length > 1 ? specValues : specValues[0];
            }
        });
        setSelectedSpecs(specToSet);
    };

    const getBrandsToSetAsChecked = () =>
        brandQueryParamAsArray.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
        }, {});

    const getSpecsToSetAsChecked = () => {
        let specsToSetAsChecked = {};
        validSpecsForFiltering.forEach(({ type }) => {
            if (query.has(type)) {
                specsToSetAsChecked = query
                    .get(type)
                    .split(',')
                    .filter(Boolean)
                    .reduce((acc, currentValue) => {
                        acc[currentValue] = true;
                        return acc;
                    }, {});
            }
        });
        return specsToSetAsChecked;
    };

    const getPriceChipsToSetAsChecked = () => {
        const priceFromQuery = getPriceFromQuery();
        if (priceFromQuery.min !== null || priceFromQuery.max !== null) {
            return getChipsForPrice(priceFromQuery);
        }
        return {};
    };

    const handleCheckedFromQuery = () => {
        const brandsToSetAsChecked = getBrandsToSetAsChecked();
        const specsToSetAsChecked = getSpecsToSetAsChecked();
        const priceChipsToSetAsChecked = getPriceChipsToSetAsChecked();
        setChecked({
            ...checked,
            ...specsToSetAsChecked,
            ...brandsToSetAsChecked,
            ...priceChipsToSetAsChecked,
        });
    };

    const updateSelectedFilters = () => {
        handleQFromQuery();
        handleSelectedTypeFromQuery();
        handleSelectedBrandsFromQuery();
        handleSelectedPriceBasedOnQuery();
        handleSelectedSpecsFromQuery();
        handleCheckedFromQuery();
    };

    // Logic to append filters, pagenumber and sort (or search params) into url query
    useEffect(() => {
        const searchParams = new URLSearchParams();
        if (filterQ) searchParams.set('q', filterQ);
        const filters = prepareFilters({
            filterQ,
            price,
            selectedPrice,
            selectedBrands,
            setSelectedBrands,
            selectedSpecs,
        });
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'price') {
                const { gte, lte } = value.special.ARS;
                if (gte) searchParams.set('min', gte);
                if (lte) searchParams.set('max', lte);
            } else if (key === 'specs') {
                // eslint-disable-next-line no-restricted-syntax
                for (const [specKey, specValue] of Object.entries(value)) {
                    searchParams.append(specKey, specValue);
                }
            } else {
                searchParams.append(key, value);
            }
        }
        if (sort !== productHelper.SORT_VALUES.popularity) {
            searchParams.set('sort', sort);
        }
        const path = getRoutePath({ params, selectedType, pageRoute });
        history.replace(`${path}?${searchParams.toString()}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType, selectedBrands, selectedPrice, selectedSpecs, sort, filterQ]);

    const getProducts = ({ additionalFilters = {}, cleanProducts = false } = {}) => {
        setIsLoading(true);
        const nextPage = cleanProducts ? 1 : currentPage + 1;
        const dataForSearch = {
            type: selectedType?.type,
            page: nextPage,
            perPage: getProductsPerPage(width),
            filter: prepareFilters({
                filterQ,
                price,
                selectedPrice,
                selectedBrands,
                setSelectedBrands,
                selectedSpecs,
            }),
            sort: productHelper.createSortObject(sort),
            ...additionalFilters,
        };

        API.products
            .search(dataForSearch)
            .then(({ data: { data, total } }) => {
                setTotalPages(Math.ceil(total / getProductsPerPage(width)));
                setProducts((prevProducts) => [...(cleanProducts ? [] : prevProducts), ...data]);
                setCurrentPage(nextPage);
                setIsLoading(false);
            })
            .catch((error) => {
                handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                });
                setIsLoading(false);
            });
    };

    return {
        sort,
        price,
        params,
        perPage,
        checked,
        filterQ,
        isLoading,
        products,
        location,
        totalPages,
        selectedType,
        selectedPrice,
        selectedSpecs,
        selectedBrands,
        currentPage,
        showMobileFilters,
        setSort,
        setPrice,
        setFilterQ,
        setChecked,
        setIsLoading,
        handleSelect,
        setSelectedType,
        handleDeleteChip,
        setSelectedPrice,
        handleChangeCheck,
        handleChangePrice,
        handlePriceEntered,
        setShowMobileFilters,
        resetSelectedFilters,
        updateSelectedFilters,
        shouldResetSelectedFilters,
        shouldUpdateSelectedFilters,
        getProducts,
    };
};

export default useProductsPage;
