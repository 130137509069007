const SECOND_PART_OF_THE_TITLE = ' | GAMER FACTORY ARGENTINA';
const SECOND_PART_OF_THE_TITLE_LENGTH = SECOND_PART_OF_THE_TITLE.length;
const TITLE_MAX_LENGTH = 67;

const getLimitedLengthPageTitleForProduct = (productName) => productName.substring(0, TITLE_MAX_LENGTH - SECOND_PART_OF_THE_TITLE_LENGTH) + SECOND_PART_OF_THE_TITLE;

const seoHelper = {
    getLimitedLengthPageTitleForProduct,
};

export default seoHelper;
