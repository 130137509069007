// eslint-disable-next-line import/prefer-default-export
export const mailContent = [
    '1 - Nombre y Apellido del Comprador:',
    '2 - Número de Pedido:',
    '3 - Fecha de Compra:',
    '4 - Producto(s) que deseás devolver:',
    '5 - Motivo del Arrepentimiento (opcional):',
    '6 - Domicilio donde se retiró el producto (si corresponde):',
    '7 - Medio de Pago utilizado:',
    '8 - Correo electrónico de contacto:',
    '9 - Teléfono de contacto:',
];
