import { Box, Button, FormControl, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    textMisDatos: {
        fontWeight: 700,
        fontSize: '20px',
    },
    buttonMisDatos: {
        fontWeight: 700,
        fontSize: '12px',
        paddingLeft: 16,
    },
    textPointGD: {
        fontWeight: 300,
        fontSize: '14px',
    },
    inputText: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 17,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 17,
            paddingBottom: 17,
        },
    },
}));

const UserData = ({ user }) => {
    const classes = useStyles();
    return (
        <Box>
            <Box display="flex">
                <Typography variant="h1" className={classes.textMisDatos}>
                    Mis Datos
                </Typography>
                <Button variant="text" size="small" component={Link} to="/account/edit" className={classes.buttonMisDatos} startIcon={<Edit />}>
                    Editar
                </Button>
            </Box>
            <Grid container>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="nombre-account-id"
                            label="Nombre"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.first_name ?? ''}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="apellido-account-id"
                            label="Apellido"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.last_name ?? ''}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="phone-account-id"
                            label="Teléfono"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.shippingData?.phone ?? ''}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="mail-account-id"
                            label="Correo electrónico"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.email}
                            multiline
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="province-account-id"
                            label="Provincia"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.shippingData?.province ?? ''}
                            multiline
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="locality-account-id"
                            label="Localidad"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.shippingData?.locality ?? ''}
                            multiline
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="address-account-id"
                            label="Dirección"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.shippingData?.address ?? ''}
                            multiline
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormControl fullWidth className={classes.inputText}>
                        <TextField
                            id="floor-account-id"
                            label="Piso / Departamento"
                            InputLabelProps={{ className: classes.textPointGD }}
                            value={user.shippingData?.floor ?? ''}
                            multiline
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                className: classes.textPointGD,
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserData;
