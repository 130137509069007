/* eslint-disable max-len */
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import helper from '../../../helpers/cartHelper';

const useStyles = makeStyles(() => ({
    cardContainer: {
        padding: '10px 0',
        width: '100%',
        '@media (max-width: 800px)': {
            alignItems: 'center',
        },
    },
    cardNameContainer: {
        lineHeight: '10px',
        overflow: 'hidden',
        width: '70%',
        height: '34px',
        textOverflow: 'ellipsis',
    },
    cardPriceContainer: {
        width: '30%',
    },
    cardTextLeft: {
        fontWeight: '400!important',
        textAlign: 'start',
        '@media (max-width: 576px)': {
            fontSize: '11px',
        },
    },
    cardTextRight: {
        textAlign: 'end',
        fontWeight: '700!important',
        '@media (max-width: 576px)': {
            fontSize: '11px',
        },
    },
    divider: {
        marginRight: 8,
        '&:last-child': {
            display: 'none',
        },
    },
}));

const ModalCard = ({ spec }) => {
    const classes = useStyles();
    const body = (s) => (
        <>
            <Box className={classes.cardContainer} display="flex" justifyContent="space-between" alignItems="center">
                <Box className={classes.cardImageContainer} mr={2}>
                    <img src={s.images[0] && s.images[0].url && s.images[0].url} alt="pdctImg" width={60} />
                </Box>
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box className={classes.cardNameContainer}>
                        <Typography variant="subtitle1" className={classes.cardTextLeft}>
                            {s.quantity < 2 || !s.quantity ? s.name : `${s.name} (x ${s.quantity})`}
                        </Typography>
                    </Box>
                    <Box className={classes.cardPriceContainer} ml={3} mr={5}>
                        <Typography className={classes.cardTextRight} variant="subtitle1">
                            {helper.formatPrice(s.price.special.ARS)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider className={classes.divider} />
        </>
    );
    return <>{Array.isArray(spec) ? spec.map((specFromArray) => body(specFromArray)) : body(spec)}</>;
};

export default ModalCard;
