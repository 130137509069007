import boolean from './boolean';
import { formFactors as motherFormFactors } from './motherChoices';

const sizes = [
    { id: 'atx', name: 'ATX' },
    { id: 'sfx', name: 'SFX' },
];

const powerSupplySlotLocations = [
    { id: 'top', name: 'Superior' },
    { id: 'bottom', name: 'Inferior' },
];

const lightings = [
    { id: 'argb', name: 'ARGB' },
    { id: 'rgb', name: 'RGB' },
    { id: 'noLighting', name: 'Sin iluminación' },
];

const formFactors = [
    { id: 'Full-Tower', name: 'Full Tower' },
    { id: 'Mid-Tower', name: 'Mid Tower' },
    { id: 'Mini-Tower', name: 'Mini Tower' },
];

export default {
    powerSupplyIncluded: boolean,
    peripheralsIncluded: boolean,
    mother_form_factor: motherFormFactors,
    size: sizes,
    powerSupplySlotLocation: powerSupplySlotLocations,
    lighting: lightings,
    form_factor: formFactors,
    compatibilityWithWatercooler120: boolean,
    compatibilityWithWatercooler140: boolean,
    compatibilityWithWatercooler240: boolean,
    compatibilityWithWatercooler280: boolean,
    compatibilityWithWatercooler360: boolean,
    compatibilityWithWatercooler420: boolean,
};
