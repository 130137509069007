import { Box, Button, CircularProgress, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import cartHelper from '../../../helpers/cartHelper';
import { sendCartItemQuantityModificationGAEvent } from '../../../helpers/gaHelper';
import colors from '../../../theme/colors';
import ItemData from './ItemData';

const useStyles = makeStyles((theme) => ({
    inputNumber: {
        marginTop: 0,
        color: 'transparent',
    },
    inputNumberText: {
        textAlign: 'center',
        textShadow: '0 0 0 #1C1C1C',
    },
    price: {
        fontWeight: 400,
    },
    borderColor: {
        borderBottomColor: '#F8E837',
    },
    sizeTable: {
        paddingLeft: 0,
        width: '20%',
    },
    sizeTableTitle: {
        paddingLeft: 0,
        width: '60%',
    },
    buttonInput: {
        height: 28,
        minWidth: '28px',
        maxWidth: '28px',
        cursor: 'pointer',
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FDFDFD',
            borderColor: theme.palette.primary.main,
            fontWeight: 700,
        },
        '&:disabled': {
            color: colors.white,
            backgroundColor: '#DBDBD5',
            borderColor: '#DBDBD5',
        },
    },
    numberInputMultiSelect: {
        height: 28,
        width: '28px',
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        paddingTop: 0,
        textAlign: 'center',
    },
}));

const ItemCheckout = ({ updateCart, handleClickOpenDeleteDialog, isLastItem, item, cart }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [quantity, setQuantity] = useState(item.quantity ?? 1);

    const handleChangeQuantity = async (type) => {
        const valueToAdd = type === 'up' ? 1 : -1;
        let newQuantity = valueToAdd + quantity;
        newQuantity = newQuantity < 0 ? 0 : newQuantity;
        const cartAux = cart;
        const itemIndex = cartAux.items.findIndex((itemCart) => itemCart.id === item.id);
        const itemAux = cartAux.items[itemIndex];
        if (newQuantity > 0 && newQuantity <= itemAux.stock) {
            const oldQuantity = itemAux.quantity;
            itemAux.quantity = newQuantity;
            updateCart(cartAux);
            setQuantity(newQuantity);
            sendCartItemQuantityModificationGAEvent({
                item: {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price.special,
                },
                oldQuantity,
                newQuantity,
            });
        }
        if (newQuantity > item.stock) {
            setQuantity(item.stock);
            enqueueSnackbar(`Disculpa, solo tenemos ${item.stock} unidad/es en stock`, { variant: 'warning' });
        }
    };
    return (
        <TableRow className={classes.itemRow} key={item.id}>
            <TableCell className={`${classes.sizeTableTitle} ${isLastItem && classes.borderColor}`} size="small">
                <ItemData
                    cartItems={cart.items}
                    item={item}
                    deleteItem={() => {
                        handleClickOpenDeleteDialog(item.id, item.buildId);
                    }}
                />
            </TableCell>
            <TableCell className={`${classes.sizeTable} ${isLastItem && classes.borderColor}`} size="small">
                <Box display="flex" justifyContent="flex-start">
                    <Button className={classes.buttonInput} disabled={item.buildId || item.type === 'build'} variant="outlined" onClick={() => handleChangeQuantity('down')}>
                        -
                    </Button>
                    <TextField
                        value={quantity}
                        disabled={item.buildId || item.type === 'build'}
                        className={classes.numberInputMultiSelect}
                        InputProps={{
                            autoComplete: 'no-autocomplete',
                            inputProps: { min: 1, max: item.stock },
                            className: classes.inputNumber,
                            classes: {
                                input: classes.inputNumberText,
                            },
                            disableUnderline: true,
                        }}
                    />
                    <Button
                        className={classes.buttonInput}
                        disabled={item.buildId || item.type === 'build' || item.stock === quantity}
                        variant="outlined"
                        onClick={() => handleChangeQuantity('up')}
                    >
                        +
                    </Button>
                </Box>
            </TableCell>
            <TableCell className={`${classes.sizeTable} ${isLastItem && classes.borderColor}`} size="small">
                <Typography variant="h4" className={classes.price}>
                    {item.price?.special ? cartHelper.formatPrice(item.price.special) : <CircularProgress />}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default ItemCheckout;
