const CartProductTypes = {
    BUILD: 1,
    PRODUCT: 2,
    PRO_BUILDER: 3,
};

const ProductTypes = {
    MOTHER: 'mother',
    CPU: 'cpu',
    CPU_COOLER: 'cpu_cooler',
    RAM: 'ram',
    GPU: 'gpu',
    STORAGE: 'storage',
    CASE: 'case',
    CASE_FAN: 'case_fan',
    POWER: 'power',
    MONITOR: 'monitor',
    MOUSE: 'mouse',
    KEYBOARD: 'keyboard',
    HEADSET: 'headset',
    MICROPHONE: 'microphone',
    CHAIR: 'chair',
    ACCESSORIES: 'accessories',
    THERMAL_PASTE: 'thermal_paste',
    SPEAKERS: 'speakers',
    FURNITURE: 'furniture',
    OPERATIVE_SYSTEM: 'operative_system',
    OTHER: 'other',
    BUILD: 'build',
    NOTEBOOKS: 'notebook',
};

const CpuBrands = {
    INTEL: 'intel',
    AMD: 'amd',
};

const RamStickTypes = {
    DDR_3: 'ddr3',
    DDR_4: 'ddr4',
    DDR_5: 'ddr5',
};

const CpuSockets = {
    AM4: 'AM4',
    AM5: 'AM5',
    TRX4: 'TRX4',
    LGA1151: '1151',
    LGA1200: '1200',
    LGA1700: '1700',
    LGA2011: '2011',
};

const CaseFanSlotWidths = {
    W_120: 120,
    W_140: 140,
    W_240: 240,
    W_280: 280,
    W_360: 360,
};

const CoolerTypes = {
    AIR_COOLER: 'AirCooler',
    WATER_COOLER: 'WaterCooler',
};

const StorageConnections = {
    M2: 'M2',
    SATA: 'SATA',
    USB: 'USB',
};

const StorageTypes = {
    SSD: 'SSD',
    HDD: 'HDD',
};

const FormFactors = {
    FULL_TOWER: 'Full-Tower',
    MID_TOWER: 'Mid-Tower',
    MINI_TOWER: 'Mini-Tower',
};

module.exports = {
    CartProductTypes,
    ProductTypes,
    CpuBrands,
    RamStickTypes,
    CpuSockets,
    CaseFanSlotWidths,
    CoolerTypes,
    StorageConnections,
    FormFactors,
    StorageTypes,
};
