import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../../../api/API';
import { UserContext } from '../../../../contexts';
import handler from '../../../../helpers/handler';
import gameCover from '../../images/gameCover.png';
import ImageCheckbox from '../ImageCheckbox';

const useStyles = makeStyles(() => ({
    box: {
        display: 'grid',
        '@media (max-width: 600px)': {
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
        },
        gridTemplateColumns: 'repeat(auto-fill, minmax(122px, 1fr))',
        margin: '0 auto',
        maxWidth: 605,
        justifyContent: 'center',
    },
}));

const GameSelector = ({ updateBuildSpecs, buildSpecs }) => {
    const [availableGames, setAvailableGames] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        API.games
            .getAll()
            .then((response) => {
                setIsLoaded(true);
                setAvailableGames(response.data.data);
            })
            .catch((err) => {
                handler.handleError({ error: err, userContextDispatch: dispatch, enqueueSnackbar });
                setIsLoaded(true);
                setError(err?.message ?? 'Hubo un error conectándose al servidor');
            });
    }, [dispatch, enqueueSnackbar]);

    const toggleSelectionFor = (game) => (isSelected) => {
        if (isSelected) {
            updateBuildSpecs({ games: [...buildSpecs.games, game] });
        } else {
            updateBuildSpecs({ games: buildSpecs.games.filter((g) => g.id !== game.id) });
        }
    };

    const isGameSelected = (game) => buildSpecs.games.some((g) => g.id === game.id);

    const gameList = availableGames.map((game) => (
        <ImageCheckbox key={game.id} image={game.image.url} title={game.title} onChange={toggleSelectionFor(game)} checked={isGameSelected(game)} defaultImage={gameCover} />
    ));

    if (error) {
        return (
            <div>
                Error:
                {error}
            </div>
        );
    }

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            <Box fontWeight="fontWeightLight" mb={1}>
                ¡Podés seleccionar todas las opciones que quieras!
            </Box>
            <Box className={classes.box} mx="auto" display="flex">
                {gameList}
            </Box>
        </>
    );
};

export const defaultGames = [];
export const GameSelect = withRouter(GameSelector);
