import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';

import API from '../../../api/API';
import { UserContext } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import handler from '../../../helpers/handler';
import CartResume from '../../components/CartResume';

const Resume = ({ clearCart }) => {
    const [state, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!state.user?.cart.id) {
            API.carts
                .post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((error) => {
                    handler.handleError({ error, userContextDispatch: dispatch, enqueueSnackbar });
                });
        }
    }, [state, dispatch, enqueueSnackbar]);

    return <CartResume clearCart={clearCart} showCartButtons />;
};

export default Resume;
