/* eslint-disable max-len */
import { Box, Container, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { useEffect } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { INVOICE_TYPES, IVA_CONDITIONS } from '../../../../common/constants';
import useProvincesAndLocalities from '../../../../hooks/useProvincesAndLocalities';
import colors from '../../../../theme/colors';
import { initialBuyerData } from '../constants';

const useStyles = makeStyles(() => ({
    removeInputControls: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
            margin: 80,
        },
    },
    invoiceTypes: {
        marginLeft: '14px',
    },
    highlightedText: {
        color: colors.black,
        fontWeight: 'bold',
    },
    subTitle: {
        fontSize: '15px',
        margin: '14px 0px',
        '@media (max-width: 768px)': {
            fontSize: '14px',
            margin: '10px 0px',
        },
    },
    title: {
        fontSize: '20px',
        '@media (max-width: 768px)': {
            fontSize: '16px',
        },
    },
    titleContainer: {
        marginTop: '16px',
        '@media (max-width: 768px)': {
            marginTop: '0px',
        },
    },
}));

const Billing = ({ buyerData, goToNextStep, setBuyerData }) => {
    const classes = useStyles();

    const { localities, provinces, selectedLocality, setLocalities, setSelectedLocality, setSelectedProvince } = useProvincesAndLocalities({
        initialLocality: buyerData.locality || null,
        initialProvince: buyerData.province || null,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setBuyerData({ ...buyerData, [name]: value });
    };

    const handleInvoiceTypeChange = (event) => {
        const newInvoiceType = event.target.value;

        setBuyerData({
            ...initialBuyerData,
            invoiceType: newInvoiceType,
        });

        if (newInvoiceType === INVOICE_TYPES.finalConsumer) {
            setLocalities([]);
            setSelectedLocality(null);
            setSelectedProvince(null);
        }
    };

    useEffect(() => {
        setSelectedProvince(buyerData.province ?? null);
        setSelectedLocality(buyerData.locality ?? null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Container maxWidth="xl">
                <Box className={classes.titleContainer}>
                    <Typography variant="h1" className={classes.title}>
                        Facturación
                    </Typography>
                </Box>
                <Typography variant="h4" className={classes.subTitle}>
                    Seleccionar tipo de factura
                </Typography>
                <RadioGroup className={classes.invoiceTypes} name="invoiceType" onChange={handleInvoiceTypeChange} value={buyerData.invoiceType}>
                    <FormControlLabel
                        className={buyerData.invoiceType === INVOICE_TYPES.finalConsumer ? classes.highlightedText : ''}
                        control={<Radio size="small" color="primary" />}
                        label="Consumidor Final"
                        value={INVOICE_TYPES.finalConsumer}
                    />
                    <FormControlLabel
                        className={buyerData.invoiceType === INVOICE_TYPES.invoiceA ? classes.highlightedText : ''}
                        control={<Radio size="small" color="primary" />}
                        label="Factura A"
                        value={INVOICE_TYPES.invoiceA}
                    />
                </RadioGroup>
                <Box display="flex" flexDirection="column">
                    <Typography variant="h4" className={classes.subTitle}>
                        Datos de facturación
                    </Typography>
                    <ValidatorForm id="billingForm" onSubmit={goToNextStep} autoComplete="off">
                        <Grid container spacing={2}>
                            {buyerData.invoiceType === INVOICE_TYPES.finalConsumer && (
                                <>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu nombre.']}
                                            fullWidth
                                            id="firstName"
                                            label="Nombre*"
                                            name="firstName"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu apellido.']}
                                            fullWidth
                                            id="lastName"
                                            label="Apellido*"
                                            name="lastName"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu número de documento.']}
                                            fullWidth
                                            id="dni"
                                            label="DNI*"
                                            name="dni"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.dni}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu teléfono.']}
                                            fullWidth
                                            id="phone"
                                            InputProps={{ classes: { root: classes.removeInputControls } }}
                                            label="Teléfono de contacto*"
                                            name="phone"
                                            onChange={handleChange}
                                            type="number"
                                            validators={['required']}
                                            value={buyerData.phone}
                                        />
                                    </Grid>
                                </>
                            )}
                            {buyerData.invoiceType === INVOICE_TYPES.invoiceA && (
                                <>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu CUIT']}
                                            fullWidth
                                            id="cuit"
                                            label="CUIT* (sin guiones)"
                                            name="cuit"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.cuit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu razón social']}
                                            fullWidth
                                            id="businessName"
                                            label="Razón social*"
                                            name="businessName"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.businessName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <SelectValidator
                                            errorMessages={['Selecciona tu condición frente al IVA']}
                                            fullWidth
                                            id="iva-condition"
                                            label="Seleccionar condición frente al IVA*"
                                            name="ivaCondition"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.ivaCondition}
                                        >
                                            {IVA_CONDITIONS.map(({ name, value }) => (
                                                <MenuItem key={name} value={value}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </SelectValidator>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Autocomplete
                                            value={buyerData.province}
                                            autoComplete={false}
                                            disableClearable
                                            options={provinces}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(_, newProvince) => {
                                                setSelectedProvince(newProvince);
                                                setSelectedLocality(null);
                                                setBuyerData({
                                                    ...buyerData,
                                                    province: newProvince,
                                                });
                                            }}
                                            getOptionSelected={(option, value) => value.name === option.name}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    value={buyerData.province}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'no-autocomplete',
                                                    }}
                                                    label="Provincia*"
                                                    validators={['required']}
                                                    errorMessages={['Selecciona tu provincia']}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Autocomplete
                                            value={selectedLocality}
                                            autoComplete={false}
                                            disableClearable
                                            options={localities}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(_, newLocality) => {
                                                setSelectedLocality(newLocality);
                                                setBuyerData({
                                                    ...buyerData,
                                                    locality: newLocality,
                                                });
                                            }}
                                            getOptionSelected={(option, value) => value.name === option.name}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    value={selectedLocality}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'no-autocomplete',
                                                    }}
                                                    label="Localidad*"
                                                    validators={['required']}
                                                    errorMessages={['Selecciona tu localidad']}
                                                />
                                            )}
                                            disabled={!buyerData.province}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu domicilio comercial']}
                                            fullWidth
                                            id="businessAddress"
                                            label="Domicilio comercial* (calle y número)"
                                            name="businessAddress"
                                            onChange={handleChange}
                                            validators={['required']}
                                            value={buyerData.businessAddress}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator fullWidth id="floor" label="Piso/Depto" name="floor" onChange={handleChange} value={buyerData.floor} />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextValidator
                                            errorMessages={['Ingresa tu teléfono.']}
                                            fullWidth
                                            id="phone"
                                            InputProps={{ classes: { root: classes.removeInputControls } }}
                                            label="Teléfono de contacto*"
                                            name="phone"
                                            onChange={handleChange}
                                            type="number"
                                            validators={['required']}
                                            value={buyerData.phone}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </ValidatorForm>
                </Box>
            </Container>
        </>
    );
};

export default Billing;
