import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';

import API from '../../../api/API';
import { UserContext } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import handler from '../../../helpers/handler';
import proBuildHelper from '../../../helpers/proBuildHelper';
import DialogProductDeleteConfirmation from './DialogProductDeleteConfirmation';
import ItemCheckout from './ItemCheckout';

const useStyles = makeStyles(() => ({
    tableTitle: {
        fontWeight: 300,
        color: '#727272',
    },
    noBorderBottom: {
        borderBottom: 'none',
    },
}));

const Items = ({ updateCart, deleteItem }) => {
    const classes = useStyles();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const [cart, setCart] = useState([]);
    const [itemIdToDelete, setItemIdToDelete] = useState(-1);
    const [buildIdToDelete, setBuildIdToDelete] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (state.user?.cart.id && state.user.cart.items) {
            setCart(state.user.cart);
        } else {
            API.carts
                .post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((error) => {
                    handler.handleError({
                        error,
                        userContextDispatch: dispatch,
                        enqueueSnackbar,
                    });
                });
        }
    }, [state, dispatch, state.user.cart?.items, enqueueSnackbar]);

    const handleClickOpenDeleteDialog = (itemId, buildId = null) => {
        setItemIdToDelete(itemId);
        if (buildId) {
            setBuildIdToDelete(buildId);
        }
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteItem = () => {
        deleteItem(itemIdToDelete, buildIdToDelete);
        handleCloseDeleteDialog();
    };

    const products = proBuildHelper.formatProductsWithBuilds(cart.items);
    return (
        <Box>
            {products && products.length > 0 ? (
                <Table>
                    <TableBody>
                        <TableRow className={classes.noBorderBottom}>
                            <TableCell padding="none" size="small" style={{ borderBottom: 'none' }}>
                                <Typography className={classes.tableTitle} variant="h6">
                                    Producto
                                </Typography>
                            </TableCell>
                            <TableCell padding="none" size="small" style={{ borderBottom: 'none' }}>
                                <Typography className={classes.tableTitle} variant="h6">
                                    Cantidad
                                </Typography>
                            </TableCell>
                            <TableCell padding="none" size="small" style={{ borderBottom: 'none' }}>
                                <Typography className={classes.tableTitle} variant="h6">
                                    Precio unitario
                                </Typography>
                            </TableCell>
                            <TableCell size="small" style={{ borderBottom: 'none' }} />
                        </TableRow>
                        {products.map((item, i) => {
                            const isLastItem = state.user.cart?.items.length === i + 1;
                            const keyID = `${item.id}-key${i}`;
                            return (
                                <ItemCheckout key={keyID} updateCart={updateCart} handleClickOpenDeleteDialog={handleClickOpenDeleteDialog} isLastItem={isLastItem} item={item} cart={cart} />
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <Typography className={classes.tableTitle} variant="h6">
                    No hay items en el carrito
                </Typography>
            )}
            <DialogProductDeleteConfirmation openDeleteDialog={openDeleteDialog} handleCloseDeleteDialog={handleCloseDeleteDialog} handleDeleteItem={handleDeleteItem} />
        </Box>
    );
};

export default Items;
