import boolean from './boolean';

const connectivities = [
    { id: 'wired', name: 'Cableado' },
    { id: 'wireless', name: 'Inalámbrico' },
    { id: 'hybrid', name: 'Híbrido' },
];

const lightingTypes = [
    { id: 'argb', name: 'ARGB' },
    { id: 'rgb', name: 'RGB' },
    { id: 'fixedColor', name: 'Color fijo' },
    { id: 'noLighting', name: 'Sin iluminación' },
];

export default {
    adjustableDPI: boolean,
    connectivity: connectivities,
    lighting: lightingTypes,
    programmableButtons: boolean,
};
