import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { isObject, uniqBy } from 'lodash';
import { useState } from 'react';

import textHelpers from '../../helpers/textHelpers';

const useStyles = makeStyles(() => ({
    specTitle: {
        fontWeight: 700,
        paddingTop: 10,
    },
    hiddeButton: {
        width: '100%',
        borderBottom: '1px solid',
        justifyContent: 'flex-start',
        paddingLeft: 0,
    },
    hideButtonIcon: {
        marginLeft: 'auto',
    },
}));

const CustomField = ({ specKey, specValue, productType = undefined }) => {
    if (Array.isArray(specValue)) {
        return Object.values(specValue).map((idSpecValue, index) => {
            const textToShow = textHelpers.getSpecValueToShow(specKey, idSpecValue, productType);
            const keyID = `arraySpecsProduct-${index}`;
            return <Typography dangerouslySetInnerHTML={{ __html: index ? `, ${textToShow}` : textToShow }} variant="subtitle1" align="left" key={keyID} />;
        });
    }
    if (isObject(specValue)) {
        if (specValue.description) {
            const textToShow = textHelpers?.cleanDescription(specValue.description);
            return <Typography dangerouslySetInnerHTML={{ __html: textToShow }} variant="subtitle1" align="left" />;
        }
        const uniqueProducts = uniqBy(specValue.products, 'id');
        if (uniqueProducts.length > 0) {
            return uniqueProducts.map((product, index) => {
                const keyID = `objectSpecsProduct-${index}`;
                return <Typography dangerouslySetInnerHTML={{ __html: index ? `, ${product.name}` : product.name }} variant="subtitle1" align="left" key={keyID} />;
            });
        }
        return <Typography dangerouslySetInnerHTML={{ __html: 'N/A' }} variant="subtitle1" align="left" />;
    }
    const textToShow = textHelpers.getSpecValueToShow(specKey, specValue, productType);
    return <Typography dangerouslySetInnerHTML={{ __html: textToShow }} variant="subtitle1" align="left" />;
};

const SpecsProduct = ({ specs, productType = undefined }) => {
    const classes = useStyles();
    const specifications = Object.entries({ ...specs });
    const [showSpecs, setShowSpecs] = useState(true);

    const handleShowSpecs = () => {
        setShowSpecs(!showSpecs);
    };

    return (
        <>
            <Box mt={2} display="flex">
                <Button
                    className={classes.hiddeButton}
                    onClick={handleShowSpecs}
                    classes={{ iconSizeMedium: classes.hideButtonIcon }}
                    endIcon={showSpecs ? <ArrowDropUp /> : <ArrowDropDown />}
                >
                    ESPECIFICACIONES
                </Button>
            </Box>
            <Box mb={2} display={showSpecs ? 'flex' : 'none'}>
                <Grid container>
                    {specifications.map(([specKey, specValue], index) => {
                        const keyID = `${index}specsProduct`;
                        const doNotShowSpecs = ['games_compatibility', 'customizable'];
                        const invalidData = [null, undefined, NaN, 'N/A', ''];
                        if (invalidData.includes(specValue) || doNotShowSpecs.includes(specKey) || specValue.length === 0 || (!specValue.description && specValue.products?.length === 0)) {
                            return null;
                        }
                        return (
                            <Grid item xs={12} sm={6} lg={4} key={keyID}>
                                <Box display="flex">
                                    <Typography className={classes.specTitle} variant="subtitle1">
                                        {textHelpers.getSpecTitle(specKey, productType)}
                                    </Typography>
                                </Box>
                                <Box display="flex" pr={10} align="left">
                                    <CustomField specKey={specKey} specValue={specValue} productType={productType} />
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </>
    );
};

export default SpecsProduct;
