import { Box, CircularProgress, IconButton, TextField, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';

import API from '../../../api/API';
import { UserContext } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import { useCouponStyles } from './useStyles';

const CouponCode = () => {
    const classes = useCouponStyles();
    const [isCouponCodeInputOpen, setIsCouponCodeInputOpen] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [coupon, setCoupon] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const handleCouponCodeChange = (e) => setCoupon(e.target.value);

    const handleCouponCodeButtonClick = async () => {
        if (state.user?.cart?.appliedCoupon?.code === coupon) {
            enqueueSnackbar('El código ingresado es el ya existente.', { variant: 'error' });
        } else {
            setIsLoading(true);
            await API.carts
                .applyCoupon({ coupon, id: state.user.cart.id })
                .then((response) => {
                    dispatch({
                        type: userConstants.UPDATE_COUPON,
                        appliedCoupon: response.data.appliedCoupon,
                    });
                    setIsCouponCodeInputOpen(false);
                })
                .catch((error) => {
                    if (error.response?.status === 404) enqueueSnackbar('El código ingresado es inválido.', { variant: 'warning' });
                    else enqueueSnackbar('Hubo un problema al validar el código. Intenta nuevamente en unos minutos.', { variant: 'error' });
                })
                .finally(() => setIsLoading(false));
        }
    };

    const onKeyDownCouponCodeInput = (e) => {
        if (e.keyCode === 13) handleCouponCodeButtonClick();
    };

    return (
        <>
            {isCouponCodeInputOpen && (
                <Box className={classes.couponCodeInputContainer}>
                    <TextField
                        id="code"
                        label="Introducí tu código"
                        variant="outlined"
                        fullWidth
                        onChange={handleCouponCodeChange}
                        className={classes.couponCodeInputTextfield}
                        inputProps={{
                            style: {
                                padding: 10.5,
                            },
                        }}
                        onKeyDown={onKeyDownCouponCodeInput}
                    />

                    <IconButton type="button" className={classes.couponCodeInputIconButton} onClick={handleCouponCodeButtonClick}>
                        {isLoading ? <CircularProgress size={20} /> : <PlayArrowIcon className={classes.couponCodeInputIcon} />}
                    </IconButton>
                </Box>
            )}

            {!isCouponCodeInputOpen && !state.user?.cart?.appliedCoupon && (
                <Box className={classes.couponCodeContainer}>
                    <Typography display="inline" variant="subtitle1">
                        ¿Tenés un cupón?&nbsp;
                    </Typography>
                    <Typography display="inline" variant="subtitle1" className={classes.couponClickHereButton} onClick={() => setIsCouponCodeInputOpen(true)}>
                        Hacé click acá
                    </Typography>
                </Box>
            )}
        </>
    );
};
export default CouponCode;
