import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    actionButtons: {
        marginBottom: 22,
        display: 'flex',
        justifyContent: 'center',
    },
    confirmationText: {
        fontWeight: 300,
    },
}));

const DialogProductDeleteConfirmation = (props) => {
    const classes = useStyles();
    const { openDeleteDialog, handleCloseDeleteDialog, handleDeleteItem } = props;

    return (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                <Button onClick={handleCloseDeleteDialog}>
                    <CloseIcon />
                </Button>
            </Box>
            <DialogTitle id="form-dialog-title">
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6">Eliminar producto</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="center">
                    <DialogContentText className={classes.confirmationText}>¿Querés eliminar el producto del carrito?</DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions className={classes.actionButtons}>
                <FormControl>
                    <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                        Cancelar
                    </Button>
                </FormControl>
                <FormControl>
                    <Button variant="contained" onClick={handleDeleteItem}>
                        Eliminar
                    </Button>
                </FormControl>
            </DialogActions>
        </Dialog>
    );
};

export default DialogProductDeleteConfirmation;
