/* eslint-disable max-len */
import { Checkbox, Grid, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SHIPPING_TYPES } from '../../../../../../../common/constants';

const useStyles = makeStyles(() => ({
    agencyAddress: {
        fontWeight: 400,
    },
    agencyData: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '20px 0px',
        width: '100%',
    },
}));

const MatchedAgencies = ({ agencies = [], onAgencyClick = () => {}, province, shippingData }) => {
    const classes = useStyles();

    const isPickupAtNearestAgency = shippingData.shippingType === SHIPPING_TYPES.pickupAtNearestAgency;

    return (
        <List component="div" disablePadding>
            {agencies.map((agency) => (
                <ListItem variant="h6" key={agency.id}>
                    <Grid className={classes.agencyData}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={classes.agencyAddress}>
                                {agency.address}
                            </Typography>
                            <Typography variant="subtitle2">{`${province.name} - ${agency.name}`}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ flexBasis: 0 }}>
                            <Checkbox checked={isPickupAtNearestAgency && shippingData.address === agency.address} onChange={({ target }) => onAgencyClick(agency, target.checked)} />
                        </Grid>
                    </Grid>
                </ListItem>
            ))}
        </List>
    );
};

export default MatchedAgencies;
