import { Box, Hidden, TablePagination, Typography, makeStyles } from '@material-ui/core';
import { useState } from 'react';

import colors from '../../theme/colors';
import OrderDetails from './OrderDetails';

const useStyles = makeStyles(() => ({
    textCurrentBuy: {
        fontWeight: 700,
        fontSize: '16px',
    },
    labelRowsPerPage: {
        fontWeight: 300,
        color: colors.blackGrey,
        fontSize: '12px',
    },
}));

const CurrentOrders = ({ orders }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const currentOrders = orders.filter((order) => order.status !== 'completed');

    return (
        <>
            {currentOrders.length ? (
                <Box>
                    <Box display="flex" pb={1}>
                        <Typography variant="h6" className={classes.textCurrentBuy}>
                            Compras actuales
                        </Typography>
                    </Box>
                    {currentOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
                        <OrderDetails key={order.code} order={order} showTitleOnDesktop={index === 0} />
                    ))}
                    <Hidden xsDown>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            labelRowsPerPage={<Box className={classes.labelRowsPerPage}>Resultados por página</Box>}
                            labelDisplayedRows={(row) => (
                                <Box flexGrow={1} className={classes.labelRowsPerPage} justifyContent="flex-end">
                                    {row.from}
                                    {' - '}
                                    {row.to}
                                    {' de '}
                                    {row.count}
                                </Box>
                            )}
                            component="div"
                            count={currentOrders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            // style={{ display: 'flex' }}
                            SelectProps={{
                                style: {
                                    fontWeight: 300,
                                    color: colors.blackGrey,
                                    fontSize: '12px',
                                },
                            }}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                                style: { color: colors.blackGrey },
                                autoid: 'pagination-button-next-collector',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                style: { color: colors.blackGrey },
                                autoid: 'pagination-button-previous-collector',
                            }}
                        />
                    </Hidden>
                </Box>
            ) : null}
        </>
    );
};

export default CurrentOrders;
