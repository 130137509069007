import { CaseFanSlotWidths, ProductTypes, RamStickTypes } from '../types';
import boolean from './boolean';
import caseChoices from './caseChoices';
import caseFanChoices from './caseFanChoices';
import chairChoices from './chairChoices';
import cpuChoices from './cpuChoices';
import cpuCoolerChoices from './cpuCoolerChoices';
import gpuChoices from './gpuChoices';
import headsetChoices from './headsetChoices';
import keyboardChoices from './keyboardChoices';
import monitorChoices from './monitorChoices';
import motherChoices from './motherChoices';
import mouseChoices from './mouseChoices';
import powerChoices from './powerChoices';
import ramChoices from './ramChoices';
import storageChoices from './storageChoices';

const productTypes = [
    { id: ProductTypes.MOTHER, name: 'Mother' },
    { id: ProductTypes.CPU, name: 'CPU' },
    { id: ProductTypes.CPU_COOLER, name: 'CPU Cooler' },
    { id: ProductTypes.RAM, name: 'RAM' },
    { id: ProductTypes.GPU, name: 'GPU' },
    { id: ProductTypes.STORAGE, name: 'Storage' },
    { id: ProductTypes.CASE, name: 'Case' },
    { id: ProductTypes.CASE_FAN, name: 'Case fan' },
    { id: ProductTypes.POWER, name: 'Power' },
    { id: ProductTypes.MONITOR, name: 'Monitor' },
    { id: ProductTypes.MOUSE, name: 'Mouse' },
    { id: ProductTypes.KEYBOARD, name: 'Keyboard' },
    { id: ProductTypes.HEADSET, name: 'Headset' },
    { id: ProductTypes.MICROPHONE, name: 'Microphones' },
    { id: ProductTypes.CHAIR, name: 'Chair' },
    { id: ProductTypes.ACCESSORIES, name: 'Accessories' },
    { id: ProductTypes.SPEAKERS, name: 'Speakers' },
    { id: ProductTypes.FURNITURE, name: 'Furniture' },
    { id: ProductTypes.OPERATIVE_SYSTEM, name: 'Operative System' },
    { id: ProductTypes.OTHER, name: 'Other' },
    { id: ProductTypes.BUILD, name: 'Pc gamer' },
    { id: ProductTypes.NOTEBOOKS, name: 'Notebooks' },
];

const ramStickTypes = [
    { id: RamStickTypes.DDR_3, name: 'DDR3' },
    { id: RamStickTypes.DDR_4, name: 'DDR4' },
    { id: RamStickTypes.DDR_5, name: 'DDR5' },
];

const radiatorWidth = [
    { id: '240', name: '240mm' },
    { id: '360', name: '360mm' },
];

const coolerWidths = [
    { id: CaseFanSlotWidths.W_120, name: '120mm' },
    { id: CaseFanSlotWidths.W_140, name: '140mm' },
    { id: CaseFanSlotWidths.W_240, name: '240mm' },
    { id: CaseFanSlotWidths.W_280, name: '280mm' },
    { id: CaseFanSlotWidths.W_360, name: '360mm' },
];

const genericSpecChoices = {
    brand: cpuChoices.brand,
    cpu_brand: cpuChoices.brand,
    case_radiator_width: coolerWidths,
    cpu_cooler: boolean,
    has_cooler: boolean,
    video_output: boolean,
    igpu: boolean,
    mother_form_factor: motherChoices.form_factor,
    product_types: productTypes,
    radiator_width: radiatorWidth,
    ram_stick_type: ramStickTypes,
    stick_type: ramStickTypes,
    cpu_socket: cpuChoices.socket,
    socket: cpuChoices.socket,
    sockets: cpuChoices.socket,
};

const specChoicesForProductType = {
    ram: ramChoices,
    cpu: cpuChoices,
    gpu: gpuChoices,
    mouse: mouseChoices,
    monitor: monitorChoices,
    keyboard: keyboardChoices,
    cpu_cooler: cpuCoolerChoices,
    case_fan: caseFanChoices,
    headset: headsetChoices,
    chair: chairChoices,
    mother: motherChoices,
    storage: storageChoices,
    power: powerChoices,
    case: caseChoices,
};

export default {
    genericSpecChoices,
    specChoicesForProductType,
};
