import { Box, Divider, Typography } from '@material-ui/core';
import { useState } from 'react';

import CartButtons from './CartButtons';
import CouponCode from './CouponCode';
import Details from './Details';
import FreeShippingBanner from './FreeShippingBanner';
import ProductsTotal from './ProductsTotal';
import ShippingCost from './ShippingCost';
import TotalPrice from './TotalPrice';
import useStyles from './useStyles';

const CartResume = ({
    resumeRef = null,
    clearCart = () => {},
    actualStep = 0,
    shippingData = null,
    selectedPaymentMode = null,
    showDetailsButton = false,
    showShippingCosts = false,
    loadingTotalPrice = false,
    loadingShippingPrice = false,
    showCartButtons = false,
    showFreeShippingBanner = false,
}) => {
    const classes = useStyles();
    const [showDetails, setShowDetails] = useState(false);

    return (
        <>
            <Box className={classes.resume} px={4} py={2} ref={resumeRef}>
                <Box mb={4}>
                    <Typography variant="h6">Resumen de tu compra</Typography>
                </Box>
                <ProductsTotal showDetails={showDetails} setShowDetails={setShowDetails} showDetailsButton={showDetailsButton} />
                <Details showDetails={showDetails} selectedPaymentMode={selectedPaymentMode} />
                <ShippingCost actualStep={actualStep} shippingData={shippingData} showShippingCosts={showShippingCosts} loadingShippingPrice={loadingShippingPrice} />
                <Divider className={classes.totalDivider} />
                <TotalPrice shippingData={shippingData} loadingTotalPrice={loadingTotalPrice} selectedPaymentMode={selectedPaymentMode} />
                <CartButtons showCartButtons={showCartButtons} handleClearCart={clearCart} />
                <CouponCode />
            </Box>
            <FreeShippingBanner showFreeShippingBanner={showFreeShippingBanner} />
        </>
    );
};

export default CartResume;
