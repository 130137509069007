import { Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { sendItemViewGAEvent } from '../../helpers/gaHelper';
import colors from '../../theme/colors';
import CarouselPrincipal from './CarouselPrincipal';
import ProductInfo from './ProductInfo';

const useStyles = makeStyles((theme) => ({
    containerImage: {
        width: '223px',
    },
    detailsText: {
        color: '#727272',
        fontWeight: 300,
    },
    numberInput: {
        width: '38px',
        height: 40,
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        paddingTop: 0,
    },
    numberInputMultiSelect: {
        width: '38px',
        height: 40,
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        paddingTop: 0,
        textAlign: 'center',
    },
    addProduct: {
        width: '100%',
        height: 40,
    },
    addProductModal: {
        '@media (min-width: 400px)': {
            width: 136,
        },
        height: 40,
    },
    buttonInput: {
        height: 40,
        minWidth: '38px',
        cursor: 'pointer',
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FDFDFD',
            borderColor: theme.palette.primary.main,
            fontWeight: 700,
        },
        '&:disabled': {
            color: colors.white,
            backgroundColor: '#DBDBD5',
            borderColor: '#DBDBD5',
        },
    },
    inputNumber: {
        marginTop: 0,
        color: 'transparent',
        height: '100%',
    },
    inputNumberText: {
        textAlign: 'end',
        textShadow: '0 0 0 #1C1C1C',
    },
    dialogPaper: {
        '@media (max-width: 959px)': {
            maxWidth: '450px',
        },
        '@media (min-width: 960px)': {
            width: '721px',
        },
    },
    closeIcon: {
        padding: 0,
        minWidth: 0,
    },
    shoppingCartIcon: {
        width: 15,
        height: 16,
        marginRight: 3,
        marginBottom: 2,
    },
    label: {
        display: 'inline-block',
        background: colors.yellow,
        position: 'absolute',
        width: 'auto',
        zIndex: 1,
    },
    flexContainer: {
        justifyContent: 'space-between',
        '@media (max-width: 599px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    labelRelative: {
        width: '100%',
        position: 'relative',
    },
    buttonAddProduct: {
        '@media (min-width: 400px)': {
            display: 'flex',
        },
    },
}));

const ModalPreViewProduct = ({ product, open, setOpen, multiSelect, handleChangeQuantity, selectedQuantity, isSelected, handleAddProduct, showProductDetailLink }) => {
    const classes = useStyles();

    useEffect(() => {
        if (open && product) {
            sendItemViewGAEvent({
                item_id: product.id,
                item_name: product.name,
                item_category: product.type,
                price: product.price.special.ARS,
            });
        }
    }, [open, product]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ square: true }} classes={{ paper: classes.dialogPaper }} maxWidth="md" disableEnforceFocus>
            <DialogContent>
                <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Vista Rápida</Typography>
                    <Button onClick={() => setOpen(false)} className={classes.closeIcon}>
                        <CloseIcon style={{ color: '#727272' }} />
                    </Button>
                </Box>
                <Box display="flex" pt={{ xs: 0, md: 2 }} px={{ xs: 2 }} className={classes.flexContainer}>
                    <Grid container>
                        <Grid item xs={12} md={5} className={classes.labelRelative}>
                            {product.label && (
                                <Box mt={2} px={2} py={1} className={classes.label}>
                                    {product.label}
                                </Box>
                            )}
                            <Box className={classes.containerImage}>
                                <CarouselPrincipal product={product} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <ProductInfo item={product} />
                            <Box className={classes.buttonAddProduct} mt={2} mb={2}>
                                {multiSelect && (
                                    <Box display="flex" justifyContent="center" mr={1} mt={1}>
                                        <Button className={classes.buttonInput} variant="outlined" onClick={() => handleChangeQuantity(-1)}>
                                            -
                                        </Button>
                                        <TextField
                                            value={selectedQuantity}
                                            type="number"
                                            disabled
                                            className={multiSelect ? classes.numberInputMultiSelect : classes.numberInput}
                                            InputProps={{
                                                autoComplete: 'no-autocomplete',
                                                inputProps: { min: 1, max: product.stock },
                                                className: classes.inputNumber,
                                                classes: {
                                                    input: classes.inputNumberText,
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                        <Button className={classes.buttonInput} variant="outlined" onClick={() => handleChangeQuantity(+1)} disabled={product.stock === selectedQuantity}>
                                            +
                                        </Button>
                                    </Box>
                                )}
                                <Box width="100%" mt={1}>
                                    <Button
                                        onClick={() => {
                                            handleAddProduct(product);
                                            setOpen(false);
                                        }}
                                        variant="contained"
                                        fullWidth
                                        className={multiSelect ? classes.addProductModal : classes.addProduct}
                                        style={
                                            isSelected
                                                ? {
                                                      backgroundColor: colors.black,
                                                      color: colors.yellow,
                                                  }
                                                : {}
                                        }
                                    >
                                        <ShoppingCartRoundedIcon className={classes.shoppingCartIcon} />
                                        {isSelected ? 'Agregado' : 'Agregar'}
                                    </Button>
                                </Box>
                            </Box>
                            {showProductDetailLink && (
                                <Typography variant="subtitle1" align="left" className={classes.detailsText}>
                                    <Button fullWidth={!multiSelect} component={Link} to={`/producto/${encodeURIComponent(product.slug)}`} variant="text" onClick={() => setOpen(false)}>
                                        VER DETALLE DEL PRODUCTO
                                    </Button>
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModalPreViewProduct;
