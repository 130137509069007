import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import { useBrandsStyles } from './styles';

const Brands = ({ checked, brandsForFilter, handleCheckBrand }) => {
    const classes = useBrandsStyles();
    return (
        <Box display="flex" flexDirection="column" justifyContent="start" my={0.1}>
            {brandsForFilter.map((value) => (
                <FormControlLabel
                    key={value}
                    className={classes.formControl}
                    control={<Checkbox name={value.toString()} style={{ color: '#C4C4C4' }} className={classes.checkbox} onChange={handleCheckBrand} checked={Boolean(checked[value])} />}
                    label={
                        <Typography className={classes.typography} variant="subtitle1">
                            {value}
                        </Typography>
                    }
                />
            ))}
        </Box>
    );
};

export default Brands;
