import { IconButton, makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Popover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { Link, useLocation } from 'react-router-dom';

import DropdownCart from './resources/checkout/components/DropdownCart';

const useStyles = makeStyles(() => ({
    containerDropdown: {
        width: '366px',
    },
}));
const pathToHidden = ['/checkout', '/completeOrder'];

export default function GDCart({ totalCartItems }) {
    const location = useLocation();
    const shouldDisplayDropdown = pathToHidden.includes(location.pathname) || totalCartItems < 1;
    const classes = useStyles();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'cart-Popover',
    });

    return (
        <>
            <IconButton component={Link} to="/checkout" aria-label="show 4 products" color="inherit" {...bindHover(popupState)}>
                <Badge badgeContent={totalCartItems} color="secondary" overlap="rectangular">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
            {shouldDisplayDropdown ? null : (
                <Hidden xsDown>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        disableRestoreFocus
                    >
                        <div className={classes.containerDropdown}>
                            <DropdownCart />
                        </div>
                    </Popover>
                </Hidden>
            )}
        </>
    );
}
