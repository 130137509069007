/* eslint-disable max-len */
import Axios from 'axios';

import { SHIPPING_TYPES } from '../common/constants';
import { ProductTypes } from '../helpers/types';

const encodeJsonForUrl = (json) => encodeURIComponent(JSON.stringify(json));

const GDAxios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

const auth = {
    login: ({ email, password, rememberMe }) => GDAxios.post('/auth/login', { email, password, rememberMe }),
    register: ({ email, password, name }) => GDAxios.post('/auth/register', { email, password, name }),
    forgotPassword: ({ email }) => GDAxios.post('/auth/forgotPassword', { email }),
    recoverPassword: ({ token, newPassword }) => GDAxios.post('/auth/recoverPassword', { token, newPassword }),
    logout: () => GDAxios.post('/auth/logout'),
};

const productsRoot = '/products';
const cartsRoot = '/carts';
const ordersRoot = '/orders';

const products = {
    get: ({ slug }) => GDAxios.get(`${productsRoot}/front`, { params: { slug } }),
    compatibles: ({ requestedTypeList, brand, build: [...buildRest], filter, page, perPage, sort }) =>
        GDAxios.post(
            `${productsRoot}/compatibles`,
            {
                requestedTypeList,
                brand,
                build: [...buildRest],
            },
            {
                params: {
                    filter: filter || '{}',
                    page,
                    perPage,
                    sort: sort || '{"field":"name","order":"ASC"}',
                },
            }
        ),
    getOperativeSystems: () => GDAxios.get(`${productsRoot}/operative-systems`),
    predict: (search) => {
        const query = `query=${encodeJsonForUrl(search)}`;

        return GDAxios.get(`${productsRoot}/predict?${query}`);
    },
    search: ({ type, filter = {}, page, perPage, sort, tag }) => {
        let query = '';

        const filterWithType = type ? { ...filter, type } : filter;

        query += `filter=${encodeJsonForUrl(filterWithType)}`;

        const pageToUse = page || 1;
        query += `&page=${pageToUse}`;

        const perPageToUse = perPage || 10;
        query += `&perPage=${perPageToUse}`;

        let sortToUse = {};
        if (sort && sort.field && sort.order) {
            sortToUse = sort;
        }
        if (tag) {
            query += `&tag=${tag}`;
        }
        query += `&sort=${encodeJsonForUrl(sortToUse)}`;

        return GDAxios.get(`${productsRoot}/search?${query}`);
    },
    easyBuilderFind: ({ cpuBrand, games, budget }) =>
        GDAxios.get(`${productsRoot}/easyBuilderFind`, {
            params: {
                cpuBrand,
                games,
                budget,
            },
        }),
    brands: ({ productType }) =>
        GDAxios.get(`${productsRoot}/brands`, {
            params: {
                productType,
            },
        }),
};

const builds = {
    getAll: () =>
        products.search({
            filter: { type: ProductTypes.BUILD },
            perPage: 999,
        }),
    get: ({ code }) => products.get(code),
    easyBuilderFind: (filter) => products.easyBuilderFind(filter),
};

const games = {
    getAll: () => GDAxios.get('/games?perPage=999'),
};

const users = {
    me: () => GDAxios.get('auth/me'),
    patchMe: (updatedData) => GDAxios.patch('/users', updatedData),
};

const carts = {
    get: (id) => GDAxios.get(`${cartsRoot}/${id}`),
    post: () => GDAxios.post(`${cartsRoot}`),
    updateCart: (cart, isListPrice) => GDAxios.put(`${cartsRoot}/${cart.id}`, { cart, isListPrice }),
    applyCoupon: ({ coupon, id }) => GDAxios.post(`${cartsRoot}/${id}/coupon`, { coupon }),
    deleteCoupon: ({ coupon, id }) => GDAxios.delete(`${cartsRoot}/${id}/coupon/${coupon}`),
};

const shipping = {
    quote: ({ productId, cartId, shippingData }) =>
        GDAxios.post('/shipping/quote', {
            productId,
            cartId,
            shippingData: {
                ...shippingData,
                codigoPostal: shippingData.locality.codigoPostal,
            },
        }),
    destinations: () => GDAxios.get('/shipping/destinations'),
    agencies: () => GDAxios.get('/shipping/agencies'),
};

const orders = {
    get: () => GDAxios.get('/users/me/orders'),
    post: ({ buyerData, cartId, paymentData, shippingData: { address, dni, firstName, floor, lastName, locality, phone, price, province, shippingType } }) =>
        GDAxios.post(`${ordersRoot}/from-cart/${cartId}`, {
            buyerData: {
                ...buyerData,
                ...(buyerData?.locality && {
                    locality: buyerData.locality.name,
                    codigoPostal: buyerData.locality.codigoPostal,
                }),
                province: buyerData?.province?.name || '',
            },
            paymentData,
            shippingData: {
                address,
                dni,
                firstName,
                floor,
                lastName,
                phone,
                price,
                shippingType,
                useTelegramForTracking: false,
                ...(shippingType !== SHIPPING_TYPES.pickupAtWarehouse && {
                    locality: locality.name,
                    codigoPostal: locality.codigoPostal,
                    province: province.name,
                }),
            },
        }),
};

const slides = {
    getAll: () => GDAxios.get('/slides?perPage=999'),
};

const brandImages = {
    getAll: () => GDAxios.get('/brandImages?perPage=999'),
};

export default {
    auth,
    brandImages,
    builds,
    carts,
    games,
    orders,
    products,
    shipping,
    slides,
    users,
};
