import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { pcGamer } from '../../data/buildTypes';
import componentsTypes from '../../data/componentsTypes';
import peripheralTypes from '../../data/peripheralTypes';
import FilterButtons from './components/FilterButtons';
import FilterChip from './components/FilterChip';
import ItemWithType from './components/ItemWithType';
import ProductFilterByType from './components/ProductFilterByType';
import { notebooksType, offersType, productsRoute } from './constants';
import { useProductFiltersStyles } from './styles';
import { isOffersRoute } from './utils';

const ProductFilters = ({
    route,
    setSelectedType,
    selectedType,
    setPrice,
    price,
    handleSelect,
    handleSelectWithoutType,
    mobile,
    filterQ,
    setFilterQ,
    handleChangeCheck,
    checked,
    setChecked,
    handleChangePrice,
    setSelectedPrice,
    selectedPrice,
    handlePriceEntered,
    setShowMobileFilters,
    handleDeleteChip,
}) => {
    const history = useHistory();
    const classes = useProductFiltersStyles();

    const handleClearOffers = () => {
        history.push(productsRoute);
    };

    return (
        <Box pl={!mobile ? 3 : 1} pt={!mobile ? 4 : 1} className={classes.filterContainer}>
            {isOffersRoute(route) && <ItemWithType handleClear={handleClearOffers} type={offersType} />}
            {!selectedType ? (
                <>
                    <FilterChip filterQ={filterQ} setFilterQ={setFilterQ} />
                    <Grid container>
                        <Grid item xs={12} sm={4} md={12} lg={12} className={classes.grid}>
                            <Typography variant="subtitle1" className={classes.filterCategoryTitle}>
                                Componentes de PC
                            </Typography>
                            <FilterButtons types={componentsTypes} setSelectedType={setSelectedType} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={12} lg={12} className={classes.grid}>
                            <Typography variant="subtitle1" className={classes.filterCategoryTitle}>
                                Periféricos
                            </Typography>
                            <FilterButtons types={peripheralTypes} setSelectedType={setSelectedType} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={12} lg={12} className={classes.grid}>
                            <Typography variant="subtitle1" className={classes.filterCategoryTitle}>
                                Pc armadas
                            </Typography>
                            <FilterButtons types={[pcGamer]} setSelectedType={setSelectedType} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={12} lg={12} className={classes.grid}>
                            <Typography variant="subtitle1" className={classes.filterCategoryTitleAndLink} onClick={() => setSelectedType(notebooksType)}>
                                Notebooks
                            </Typography>
                        </Grid>
                        {history?.location?.pathname === productsRoute && (
                            <Grid item xs={12} sm={4} md={12} lg={12}>
                                <Typography variant="subtitle1" className={classes.filterCategoryTitleAndLink} onClick={() => history.push('/ofertas')}>
                                    Ofertas
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <ProductFilterByType
                    route={route}
                    type={selectedType}
                    setPrice={setPrice}
                    price={price}
                    mobile={mobile}
                    setSelectedType={setSelectedType}
                    handleSelect={handleSelect}
                    handleSelectWithoutType={handleSelectWithoutType}
                    filterQ={filterQ}
                    setFilterQ={setFilterQ}
                    handleChangeCheck={handleChangeCheck}
                    checked={checked}
                    setChecked={setChecked}
                    setShowMobileFilters={setShowMobileFilters}
                    handleChangePrice={handleChangePrice}
                    setSelectedPrice={setSelectedPrice}
                    selectedPrice={selectedPrice}
                    handlePriceEntered={handlePriceEntered}
                    handleDeleteChip={handleDeleteChip}
                />
            )}
        </Box>
    );
};

export default ProductFilters;
