import { Box, Container, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import { EMAIL } from '../../common/constants';
import { helmetDeleteAccountConfig } from '../../data/seo';
import colors from '../../theme/colors';
import { StyledTitle, StyledTypography } from '../components/TextBlock';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 237px)',
        '@media (max-width: 959px)': {
            minHeight: 'calc(100vh - 420px)',
        },
        background: colors.black,
        color: colors.white,
    },
    container: {
        paddingTop: 24,
        paddingBottom: 24,
    },
    link: {
        color: colors.yellow,
        fontWeight: 600,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
}));

const DeleteAccount = () => {
    const classes = useStyles();
    return (
        <>
            <Helmet>
                <title>{helmetDeleteAccountConfig.title}</title>
                <meta name="description" content={helmetDeleteAccountConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetDeleteAccountConfig.title} />
                <meta property="og:description" content={helmetDeleteAccountConfig.description} />
            </Helmet>
            <Box className={classes.root}>
                <Container maxWidth="xl" className={classes.container}>
                    <Typography
                        variant="h1"
                        align="center"
                        style={{
                            marginBottom: '12px',
                            color: colors.white,
                            fontSize: '20px',
                            lineHeight: '24px',
                        }}
                    >
                        Baja de Servicio - Solicitud de Baja de Datos Personales
                    </Typography>

                    <StyledTitle>Solicitud de Baja de Datos Personales</StyledTitle>

                    <StyledTypography>Si deseás eliminar tu cuenta y todos los datos personales asociados a ella, por favor seguí los siguientes pasos:</StyledTypography>
                    <ol>
                        <li>
                            Enviá un correo a&nbsp;
                            <Link href={`mailto:${EMAIL}`}>
                                <Typography variant="span" className={classes.link}>
                                    {EMAIL}
                                </Typography>
                            </Link>
                            &nbsp;con el asunto:&nbsp;
                            <strong>Solicitud de Baja de Datos Personales.</strong>
                        </li>
                        <li>
                            En el cuerpo del correo, incluí la siguiente información:
                            <ul>
                                <li>Nombre y Apellido registrado.</li>
                                <li>Dirección de correo electrónico asociada a la cuenta.</li>
                                <li>Número de Pedido (si corresponde).</li>
                                <li>Solicitud específica: Eliminación total de los datos personales.</li>
                            </ul>
                        </li>
                    </ol>
                    <br />
                    <StyledTypography>
                        <strong>Importante:</strong>
                        &nbsp;La eliminación de tus datos implica que ya no podrás acceder a tu cuenta ni a tu historial de compras. Sin embargo, algunos datos pueden ser retenidos si son
                        necesarios para cumplir con obligaciones legales o contractuales.
                    </StyledTypography>
                    <br />
                    <StyledTypography>Una vez recibamos tu solicitud, procederemos a eliminar tus datos y te notificaremos cuando el proceso haya sido completado.</StyledTypography>
                </Container>
            </Box>
        </>
    );
};

export default DeleteAccount;
