import { ProductTypes } from '../../../../helpers/types';
import BaseProductSelect from '../BaseProductSelect';

const defaultCooler = {
    id: 'dft001',
    name: 'Cooler incluido en CPU',
    images: [{ title: 'defaultCooler.png', url: '/images/defaultCooler.png' }],
    default: true,
    code: 'DFT001',
    price: {
        list: {
            ARS: 0,
            USD: 0,
        },
        special: {
            ARS: 0,
            USD: 0,
        },
    },
    type: 'cpu_cooler',
};

const CoolerSelect = ({ updateBuildSpecs, buildSpecs, setGoToNextAllowed }) => {
    const validateNextStepAllowed = () => {
        const goToNextAllowed = buildSpecs.cpu.specs.has_cooler || (buildSpecs.cpu_cooler && buildSpecs.cpu_cooler.quantity >= 1);
        setGoToNextAllowed(goToNextAllowed);
    };

    return (
        <BaseProductSelect
            updateBuildSpecs={updateBuildSpecs}
            buildSpecs={buildSpecs}
            validateNextStepAllowed={validateNextStepAllowed}
            productType={ProductTypes.CPU_COOLER}
            buildProduct={buildSpecs.cpu_cooler}
            defaultProduct={buildSpecs.cpu.specs.has_cooler ? defaultCooler : null}
            hasDefault={buildSpecs.cpu.specs.has_cooler}
        />
    );
};

export default CoolerSelect;
