import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';

import API from '../../../api/API';
import { UserContext } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import cartHelper from '../../../helpers/cartHelper';
import { useCouponStyles } from './useStyles';

const AppliedCouponCode = ({ selectedPaymentMode }) => {
    const classes = useCouponStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteCouponCode = async () => {
        const coupon = state.user?.cart?.appliedCoupon?.code;
        await API.carts
            .deleteCoupon({ coupon, id: state.user.cart.id })
            .then(() => {
                dispatch({ type: userConstants.UPDATE_COUPON });
            })
            .catch((error) => {
                if (error.response?.status === 404) enqueueSnackbar('El código ingresado es inválido.', { variant: 'warning' });
                else enqueueSnackbar('Hubo un problema al validar el código. Intenta nuevamente en unos minutos.', { variant: 'error' });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <Box className={classes.totalSubItemContainer}>
                <Typography variant="subtitle2">
                    Cupón&nbsp;
                    <strong>{state.user?.cart?.appliedCoupon?.code}</strong>
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {isLoading ? (
                        <CircularProgress color="#000" />
                    ) : (
                        <Typography variant="subtitle2">
                            -&nbsp;
                            {cartHelper.getFormattedDiscountPrice(state.user?.cart, selectedPaymentMode)}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Typography
                variant="subtitle2"
                onClick={handleDeleteCouponCode}
                style={{
                    fontWeight: '500',
                    cursor: 'pointer',
                    marginBottom: '10px',
                    textDecoration: 'underline',
                }}
            >
                Quitar
            </Typography>
        </>
    );
};

export default AppliedCouponCode;
