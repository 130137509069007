import { Box, Button, CircularProgress, FormControl, Grid, MenuItem, TextField, Typography, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import API from '../../api/API';
import { UserContext } from '../../contexts';
import userConstants from '../../contexts/User/userConstants';
import { helmetUserEditDataConfig } from '../../data/seo';
import cartHelper from '../../helpers/cartHelper';
import { sendPageViewGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import shippingDataHelper from '../../helpers/shippingDataHelper';
import storageHelper from '../../helpers/storageHelper';
import colors from '../../theme/colors';
import LoadingButton from '../LoadingButton';
import PageContent from '../components/PageContent';
import UserPhotoEdit from './UserPhotoEdit';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    textMisDatos: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: 1,
    },
    textPointGD: {
        fontWeight: 300,
        fontSize: '14px',
    },
    inputText: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 17,
            paddingLeft: 8,
            paddingRight: 8,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 17,
            paddingBottom: 17,
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    inputSelected: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 17,
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 32,
            paddingBottom: 17,
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    userContent: {
        background: colors.black,
        color: colors.white,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            paddingBottom: 24,
            marginBottom: 17,
        },
        [theme.breakpoints.up('sm')]: {
            width: 300,
            minWidth: 300,
            maxHeight: 220,
        },
    },
    userContainer: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
            paddingTop: 46,
            paddingLeft: 62,
        },
    },
    userMyProfile: {
        paddingTop: 16,
        paddingBottom: 10,
        fontWeight: 700,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

const UserEditData = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [user, setUser] = useState([]);
    const [, dispatch] = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const provinces = cartHelper.getProvinces();
    const [localities, setLocalities] = useState([]);
    const { handleSubmit, reset, errors, control } = useForm();

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    useEffect(() => {
        const setLocalitiesBasedOnUserPreferences = async () => {
            const provinceAsObject = cartHelper.getProvinces().filter((p) => user.shippingData?.province === p.name)[0];
            const result = await shippingDataHelper.getAvailableLocalities(provinceAsObject);
            setLocalities(result);
        };
        setLocalitiesBasedOnUserPreferences();
    }, [user.shippingData?.province]);

    useEffect(() => {
        const resetForm = (values) => {
            reset(values);
        };
        API.users
            .me()
            .then((response) => {
                setUser(response.data);
                resetForm(response.data);
            })
            .catch((err) => {
                setError(err.message);
                handler.handleError({
                    error: err,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                    history,
                    redirectToHome: true,
                });
            })
            .finally(() => setIsLoaded(true));
    }, [enqueueSnackbar, history, reset, dispatch]);

    const onSubmit = (updatedData) => {
        const formattedUpdatedData = {
            first_name: updatedData.first_name,
            last_name: updatedData.last_name,
            shippingData: updatedData,
        };
        setSaving(true);
        API.users
            .patchMe(formattedUpdatedData)
            .then(() => {
                const updatedUser = {
                    ...user,
                    shippingData: { ...updatedData },
                };
                dispatch({ type: userConstants.SET_USER, user: updatedUser });
                storageHelper.setUser(updatedUser);
                enqueueSnackbar('Sus datos han sido actulizados correctamente', { variant: 'success' });
                history.push('/account');
            })
            .catch((err) => {
                handler.handleError({
                    error: err,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                    history,
                    redirectToHome: true,
                });
            })
            .finally(() => setSaving(false));
    };

    if (error) {
        return (
            <PageContent>
                Error:
                {error}
            </PageContent>
        );
    }
    if (!isLoaded) {
        return (
            <PageContent>
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </PageContent>
        );
    }

    return (
        <>
            <Helmet>
                <title>{helmetUserEditDataConfig.title}</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content={helmetUserEditDataConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetUserEditDataConfig.title} />
                <meta property="og:description" content={helmetUserEditDataConfig.description} />
            </Helmet>
            <PageContent>
                <Box className={classes.root}>
                    <Grid container className={classes.userContent}>
                        <Grid className={classes.userContainer}>
                            <Typography variant="subtitle2" className={classes.userMyProfile}>
                                Mi perfil
                            </Typography>
                            <UserPhotoEdit userPicture={user.profileImageURL ?? null} />
                        </Grid>
                    </Grid>

                    <Box pl={{ xs: 0, sm: 2, md: 3 }} flexGrow={1}>
                        <Box display="flex" pb={2}>
                            <Typography variant="h1" className={classes.textMisDatos}>
                                Mis Datos
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputText}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                value={props.value}
                                                onChange={props.onChange}
                                                error={!!errors.first_name}
                                                helperText={errors?.first_name?.message}
                                                id="nombre-account-id"
                                                label="Nombre"
                                                minRows={2}
                                                multiline
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            />
                                        )}
                                        defaultValue={user.first_name}
                                        name="first_name"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'El nombre es requerido',
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputText}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                value={props.value}
                                                onChange={props.onChange}
                                                error={!!errors.last_name}
                                                helperText={errors?.last_name?.message}
                                                id="apellido-account-id"
                                                label="Apellido"
                                                minRows={2}
                                                multiline
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            />
                                        )}
                                        defaultValue={user.last_name}
                                        name="last_name"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'El apellido es requerido',
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputText}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                value={props.value}
                                                onChange={props.onChange}
                                                error={!!errors.phone}
                                                helperText={errors?.phone?.message}
                                                id="phone-account-id"
                                                label="Teléfono"
                                                minRows={2}
                                                multiline
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            />
                                        )}
                                        defaultValue={user.shippingData?.phone}
                                        name="phone"
                                        control={control}
                                        rules={{
                                            minLength: {
                                                value: 6,
                                                message: 'La longitud minima es de 6',
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputText}>
                                    <TextField
                                        id="mail-account-id"
                                        label="Correo electrónico"
                                        defaultValue={user.email}
                                        minRows={2}
                                        multiline
                                        InputLabelProps={{ className: classes.textPointGD }}
                                        InputProps={{
                                            readOnly: true,
                                            className: classes.textPointGD,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputSelected}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                select
                                                value={props.value || user.shippingData?.province}
                                                onChange={props.onChange}
                                                error={!!errors.address}
                                                helperText={errors?.address?.message}
                                                id="province-account-id"
                                                label="Provincia"
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                minRows={2}
                                                multiline
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            >
                                                {provinces.map((province) => (
                                                    <MenuItem
                                                        key={province.id}
                                                        value={province.name}
                                                        onClick={async () => {
                                                            const result = await shippingDataHelper.getAvailableLocalities(province);
                                                            setLocalities(result);
                                                        }}
                                                    >
                                                        {province.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        defaultValue={user.shippingData?.province}
                                        name="province"
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputSelected}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                select
                                                value={props.value || user.shippingData?.locality}
                                                onChange={props.onChange}
                                                error={!!errors.address}
                                                helperText={errors?.address?.message}
                                                id="locality-account-id"
                                                label="Localidad"
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                minRows={2}
                                                multiline
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            >
                                                {localities?.map((locality) => (
                                                    <MenuItem key={locality.id} value={locality.name}>
                                                        {locality.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        defaultValue={user.shippingData?.locality}
                                        name="locality"
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputText}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                value={props.value}
                                                onChange={props.onChange}
                                                error={!!errors.address}
                                                helperText={errors?.address?.message}
                                                id="address-account-id"
                                                label="Dirección"
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                minRows={2}
                                                multiline
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            />
                                        )}
                                        defaultValue={user.shippingData?.address}
                                        name="address"
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth className={classes.inputText}>
                                    <Controller
                                        render={(props) => (
                                            <TextField
                                                value={props.value}
                                                onChange={props.onChange}
                                                error={!!errors.address}
                                                helperText={errors?.address?.message}
                                                id="floor-account-id"
                                                label="Piso / Departamento"
                                                InputLabelProps={{
                                                    className: classes.textPointGD,
                                                    shrink: true,
                                                }}
                                                minRows={2}
                                                multiline
                                                InputProps={{
                                                    className: classes.textPointGD,
                                                }}
                                            />
                                        )}
                                        defaultValue={user.shippingData?.floor}
                                        name="floor"
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box pt={6} display="flex" justifyContent="center">
                            <Box pr={1.5}>
                                <Button variant="outlined" color="secondary" onClick={() => history.push('/account')}>
                                    Cancelar
                                </Button>
                            </Box>
                            <Box pl={1.5}>
                                <LoadingButton variant="contained" color="primary" loading={saving} onClick={handleSubmit(onSubmit)}>
                                    {saving ? 'Guardando' : 'Guardar'}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PageContent>
        </>
    );
};

export default UserEditData;
