const userConstants = {
    LOGOUT: 'LOGOUT',
    SET_USER: 'SET_USER',
    DELETE_CART: 'DELETE_CART',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    UPDATE_CART: 'UPDATE_CART',
    UPDATE_COUPON: 'UPDATE_COUPON',
};

export default userConstants;
