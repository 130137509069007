import { Box, Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useContext } from 'react';

import { UserContext } from '../../../contexts';
import cartHelper from '../../../helpers/cartHelper';

const Total = ({ shippingCost, loadingTotalPrice, selectedPaymentMode }) => {
    const [state] = useContext(UserContext);

    const renderTotalPrice = () => cartHelper.getFormattedTotalPrice(state.user?.cart, selectedPaymentMode, shippingCost);

    return (
        <Grid container spacing={3} style={{ marginTop: '8px' }}>
            <Grid item xs={4}>
                <Typography variant="h6">Total</Typography>
            </Grid>
            <Grid item xs={8}>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice ? <CircularProgress color="#000" /> : <Typography variant="h3">{renderTotalPrice()}</Typography>}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Total;
