import boolean from './boolean';

export const formFactors = [
    { id: 'E-ATX', name: 'E-ATX' },
    { id: 'ATX', name: 'ATX' },
    { id: 'M-ATX', name: 'M-ATX' },
    { id: 'ITX', name: 'ITX' },
];

export default {
    form_factor: formFactors,
    integratedWifi: boolean,
};
