import { Box, Hidden, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../../../../api/API';
import { UserContext } from '../../../../../contexts';
import handler from '../../../../../helpers/handler';
import colors from '../../../../../theme/colors';
import NoResultsMessage from '../../../../components/NoResultsMessage';
import Build from './Build';

const useStyles = makeStyles(() => ({
    navigationButton: {
        display: 'flex',
        flexDirection: 'column',
        width: 70,
        flexBasis: 0,
        position: 'relative',
        top: 260,
    },
    navigationIconButton: {
        width: 36,
        height: 36,
        alignSelf: 'center',
        borderRadius: 0,
        fontWeight: 'bold',
        '&:disabled': {
            borderColor: colors.grey,
            color: `${colors.grey} !important`,
        },
        animation: '$vibrate 1.5s ease-in-out infinite alternate',
    },
    arrow: {
        fontSize: '54px',
        animation: '$arrowVibrate 1.5s ease-in-out infinite alternate',
    },
    '@keyframes vibrate': {
        '0%': {
            color: colors.yellow,
        },
        '50%': {
            color: '#FFFACF',
        },
        '100%': {
            color: colors.yellow,
        },
    },
    '@keyframes arrowVibrate': {
        '0%': {
            fontSize: '62px',
        },
        '50%': {
            fontSize: '48px',
        },
        '100%': {
            fontSize: '62px',
        },
    },
}));

const RecommendedBuildList = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [builds, setBuilds] = useState([]);
    const [error, setError] = useState(null);
    const [buildIndex, setBuildIndex] = useState(null);
    const classes = useStyles();
    const { buildSpecs, goToFirstStep } = props;
    const [, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoadingToAdd, setIsLoadingToAdd] = useState(false);

    useEffect(() => {
        const params = {
            cpuBrand: buildSpecs.cpuBrand,
            games: buildSpecs.games.map((game) => game.id),
        };

        API.builds
            .easyBuilderFind(params)
            .then((response) => {
                const buildsResult = response.data.data;
                buildsResult.sort((a, b) => {
                    if (a.price.special.ARS > b.price.special.ARS) return 1;
                    if (a.price.special.ARS < b.price.special.ARS) return -1;
                    return 0;
                });
                setBuilds(buildsResult);

                const { budget } = buildSpecs;
                let closestIndex = null;
                let closestDiff = 0;

                buildsResult.forEach(({ price }, index) => {
                    const diff = Math.abs(price.special.ARS - budget);
                    if (closestIndex === null || diff < closestDiff) {
                        closestDiff = diff;
                        closestIndex = index;
                    }
                });

                setBuildIndex(closestIndex);
                setIsLoaded(true);
            })
            .catch((err) => {
                handler.handleError({
                    error: err,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                });
                setError(err);
                setIsLoaded(true);
            });
    }, [buildSpecs, dispatch, enqueueSnackbar]);

    if (error) {
        return (
            <div>
                Error:
                {error}
            </div>
        );
    }
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const previousBuild = () => {
        setBuildIndex(buildIndex - 1);
    };

    const nextBuild = () => {
        setBuildIndex(buildIndex + 1);
    };

    function hasPrevious() {
        return buildIndex <= builds.length - 1 && buildIndex !== 0;
    }

    function hasNext() {
        return buildIndex !== builds.length - 1;
    }

    return (
        <>
            {builds && builds.length > 0 && buildIndex >= 0 ? (
                <Box display="flex">
                    <Box className={classes.navigationButton}>
                        <IconButton className={classes.navigationIconButton} onClick={previousBuild} disabled={!hasPrevious() || isLoadingToAdd}>
                            <NavigateBefore className={classes.arrow} />
                        </IconButton>
                        <Hidden xsDown>
                            <Box mt={2}>
                                <Typography variant="caption" noWrap>
                                    MENOR PRECIO
                                </Typography>
                            </Box>
                        </Hidden>
                    </Box>

                    <Build build={builds[buildIndex]} isLoadingToAdd={isLoadingToAdd} setIsLoadingToAdd={setIsLoadingToAdd} />

                    <Box className={classes.navigationButton}>
                        <IconButton className={classes.navigationIconButton} onClick={nextBuild} disabled={!hasNext() || isLoadingToAdd}>
                            <NavigateNext className={classes.arrow} />
                        </IconButton>
                        <Hidden xsDown>
                            <Box mt={2}>
                                <Typography variant="caption" noWrap>
                                    MAYOR PRECIO
                                </Typography>
                            </Box>
                        </Hidden>
                    </Box>
                </Box>
            ) : (
                <NoResultsMessage from="easyBuilder" goToFirstStep={goToFirstStep} />
            )}
        </>
    );
};

export default withRouter(RecommendedBuildList);
