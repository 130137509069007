import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import GDTheme from '../theme/GDTheme';
import colors from '../theme/colors';
import gameCheckbox from './easy-builder/images/gameCheckbox.svg';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: '16px',
        marginLeft: '16px',
        marginBottom: '22px',
        padding: GDTheme().spacing(2),
    },
    unchecked: {
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: colors.grey,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },

    checked: {
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        position: 'relative',
        filter: 'drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.15))',
        '&::after': {
            content: `url(${gameCheckbox})`,
            width: 25,
            height: 26,
            display: 'block',
            position: 'absolute',
            right: -12,
            top: -13,
        },
    },

    image: {
        height: 110,
        width: 110,
        '@media (max-width: 450px)': {
            width: 60,
            height: 60,
        },
    },
}));

const ImageRadio = ({ checked, image, title, onClick, defaultImage, ...props }) => {
    const classes = useStyles();

    return (
        <ButtonBase className={`${classes.button} ${checked ? classes.checked : classes.unchecked}`} onClick={onClick} {...props}>
            <img className={classes.image} src={image ?? defaultImage} alt={title} />
        </ButtonBase>
    );
};

export default ImageRadio;
