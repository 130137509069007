import { ProductTypes } from '../../../../helpers/types';
import BaseProductSelect from '../BaseProductSelect';

const PowerSelect = ({ updateBuildSpecs, buildSpecs, setGoToNextAllowed }) => {
    const validateNextStepAllowed = () => {
        setGoToNextAllowed(buildSpecs.power && buildSpecs.power.quantity >= 1);
    };

    return (
        <BaseProductSelect
            updateBuildSpecs={updateBuildSpecs}
            buildSpecs={buildSpecs}
            validateNextStepAllowed={validateNextStepAllowed}
            productType={ProductTypes.POWER}
            buildProduct={buildSpecs.power}
        />
    );
};

export default PowerSelect;
