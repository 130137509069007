import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
    quantityNumber: {
        width: 28,
        height: 28,
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 2,
        userSelect: 'none',
    },
    changeQuantityButton: {
        height: 28,
        width: 24,
        minWidth: 0,
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: 14,
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FDFDFD',
            borderColor: theme.palette.primary.main,
        },
        '&:disabled': {
            color: colors.white,
            backgroundColor: '#DBDBD5',
            borderColor: '#DBDBD5',
        },
    },
}));

const ProductQuantitySelection = ({ onQuantityChanged, selectedQuantity, maxQuantity, disabled = false }) => {
    const classes = useStyles();
    return (
        <Box display="flex" justifyContent="center" mb={1}>
            <Button className={classes.changeQuantityButton} variant="outlined" onClick={() => onQuantityChanged(-1)} disabled={disabled || selectedQuantity === 1}>
                -
            </Button>
            <Typography className={classes.quantityNumber}>{selectedQuantity}</Typography>
            <Button className={classes.changeQuantityButton} variant="outlined" onClick={() => onQuantityChanged(+1)} disabled={disabled || maxQuantity === selectedQuantity}>
                +
            </Button>
        </Box>
    );
};
export default ProductQuantitySelection;
