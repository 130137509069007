const withWhiteLineBackground = (backgroundColor, rightSide = false) => ({
    backgroundColor,
    position: 'relative',
    '&::after': {
        width: 24,
        backgroundColor: '#fafafa',
        display: 'block',
        height: '100%',
        position: 'absolute',
        top: 0,
        content: '""',
        ...(rightSide && { right: 200 }),
        ...(!rightSide && { left: 200 }),
    },
    '&>*': {
        position: 'relative',
        zIndex: 1,
    },
});

// eslint-disable-next-line import/prefer-default-export
export { withWhiteLineBackground };
