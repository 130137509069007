import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import specs from '../../../data/specs';

const TypeSpecs = ({ checked, selected, handleCheckTypeSpec }) => (
    <>
        {Object.keys(specs).map((selectedProduct) => (
            <Box key={selectedProduct} display="flex" flexDirection="column" justifyContent="start" my={0.1}>
                {selectedProduct === selected &&
                    Object.values(specs[selectedProduct].specs).map((selectedSpec) => (
                        <Box key={selectedSpec.name}>
                            {Boolean(selectedSpec.values.length) && (
                                <Typography variant="subtitle1" style={{ marginBottom: 5, fontWeight: 700 }}>
                                    {selectedSpec.name}
                                </Typography>
                            )}
                            {selectedSpec.values.map((value) => (
                                <FormControlLabel
                                    key={value}
                                    style={{ marginLeft: 3 }}
                                    control={
                                        <Checkbox
                                            name={value.toString()}
                                            style={{
                                                backgroundColor: '#F1F1F1',
                                                color: '#C4C4C4',
                                                fontSize: 1,
                                            }}
                                            onChange={(e) => handleCheckTypeSpec(e, selectedSpec)}
                                            checked={checked[value] === true}
                                        />
                                    }
                                    label={
                                        <Typography
                                            style={{
                                                marginTop: 10,
                                                marginLeft: 5,
                                                marginBottom: 8,
                                                textTransform: 'capitalize',
                                                color: '#727272',
                                            }}
                                            variant="subtitle1"
                                        >
                                            {value}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Box>
                    ))}
            </Box>
        ))}
    </>
);

export default TypeSpecs;
