export const purchasesTexts = [
    'El comprador puede seleccionar artículos del listado de productos, builds, easy builder y probuilder.',
    'El easy-builder es un configurador rápido basado en pocas preguntas para ofrecer el build ideal, al finalizar, sólo tenés que preocuparte por elegir el teclado, mouse y monitor u otro periféricos, si así lo necesitas.',
    'El pro-builder es un configurador avanzado que permite elegir componente por componente para armar un build de forma específica, siempre te vamos a mostrar componentes compatibles con lo previamente elegido. No te olvides de elegir tus periféricos preferidos 😈',
];

export const paymentsTexts = [
    'Ofrecemos 3 formas de pago: Transferencia bancaria, Crédito/Debito 1 pago, Crédito 3 y 6 cuotas.',
    'El pago en 3 y 6 cuotas se calcula a partir del precio de lista del producto, no obtiene el precio especial que figura en los otros métodos de pago.',
    'Cualquier inconveniente con el pago puede comunicarse al WhatsApp que figura en el pie de la página.',
];

export const shipmentsTexts = [
    'Una vez efectuada la compra y aprobado el pago se procederá al armado de la PC si así lo requiere la orden. Por lo general los despachos se realizan al día siguiente a todo el país y puede demorar 7 días hábiles o más dependiendo la zona.',
    'Ofrecemos 2 formatos de envío, a domicilio y a sucursal “Vía Cargo”.',
    'El comprador puede elegir si lo quiere recibir en domicilio o seleccionar una sucursal para ir a retirar.',
    'Para el retiro en sucursal se solicita presentarse con el DNI para que le entreguen el pedido.',
    'Cualquier inconveniente con el envío puede comunicarse al WhatsApp que figura en el pie de la página.',
];

export const warrantyTexts = [
    'Los productos cargados en la página muestran un periodo de garantía ofrecido por el fabricante. En caso de falla de fábrica comunicarse al WhatsApp que figura en el pie de la página para iniciar el trámite de RMA/Garantía y nosotros nos ocupamos de gestionar el caso de la manera más eficiente, queremos que estes jugando lo antes posible!',
];

export const regretConditions = [
    'El producto debe estar en las mismas condiciones en las que fue recibido, sin uso y con su embalaje original completo.',
    'Los costos de devolución estarán a cargo del cliente, excepto en los casos en que el producto haya llegado defectuoso o incorrecto.',
];

export const regretMoneyConditions = [
    'Una vez recibido el producto y confirmadas sus condiciones, procederemos al reintegro del importe abonado dentro de los 10 días hábiles. El reembolso se realizará mediante el mismo medio de pago utilizado en la compra.',
];

export const accountDeleteRequest = [
    'Eliminaremos tu cuenta y toda la información asociada de nuestra base de datos.',
    'No tendrás acceso a tu historial de compras ni recibirás más comunicaciones de nuestra parte.',
    'Es posible que ciertos datos deban ser retenidos para cumplir con obligaciones legales (por ejemplo, datos de facturación).',
];
