import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    stepsItems: {
        display: 'flex',
        marginTop: 16,
        marginBottom: 0,
        padding: 0,
        justifyContent: 'center',
    },
    stepButton: {
        color: '#727272',
        paddingTop: 0,
        textTransform: 'none',
        fontSize: 12,
        fontWeight: 300,
    },
    stepButtonDisabled: {
        color: '#1C1C1C !important',
        textTransform: 'none',
        fontSize: 12,
        fontWeight: 700,
    },
    stepItems: {
        marginLeft: 12,
    },
    paddingStep: {
        paddingBottom: 16,
    },
}));

const getOrderTracking = (type) =>
    `/images/orderTracking${
        {
            new: '0',
            payed: '1',
            shippingRequested: '1',
            delivering: '2',
            completed: '3',
        }[type]
    }.svg`;

const getHeightOrderTracking = {
    new: 120,
    payed: 136,
    shippingRequested: 136,
    delivering: 152,
    completed: 160,
};

const CurrentOrdersStatus = ({ status, statusDates }) => {
    const classes = useStyles();
    const steps = [
        {
            name: 'Compra realizada',
            date: statusDates.new ? moment(statusDates.new, false).format('DD/MM/YYYY - H.mm') : null,
        },
        {
            name: 'Pago aprobado',
            date: statusDates.payed ? moment(statusDates.payed, false).format('DD/MM/YYYY - H.mm') : null,
        },
        {
            name: 'Despacho',
            date: statusDates.delivering ? moment(statusDates.delivering, false).format('DD/MM/YYYY - H.mm') : null,
        },
        {
            name: 'Entrega',
            date: statusDates.completed ? moment(statusDates.completed, false).format('DD/MM/YYYY - H.mm') : null,
        },
    ];

    return (
        <Container maxWidth="xl" className={classes.stepsItems}>
            <img src={getOrderTracking(status)} height={getHeightOrderTracking[status] ?? 106} alt="tracking" />
            <Box className={classes.stepItems}>
                {steps.map((step) => (
                    <Box className={classes.stepItem} key={step.name}>
                        <Typography
                            classes={{
                                root: step.date ? classes.stepButtonDisabled : classes.stepButton,
                            }}
                            className={classes.paddingStep}
                            variant="subtitle2"
                        >
                            {step.name}
                            <Typography classes={{ root: classes.stepButton }}>{step.date}</Typography>
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Container>
    );
};

export default CurrentOrdersStatus;
