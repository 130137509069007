const material = [
    { id: 'syntheticLeather', name: 'Cuero Sintético' },
    { id: 'fabric', name: 'Tela' },
];

const maximumSupportedWeight = [
    { id: '100kg', name: '100 kg' },
    { id: '120kg', name: '120 kg' },
    { id: '150kg', name: '150 kg' },
];

export default {
    material,
    maximumSupportedWeight,
};
