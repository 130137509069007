import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import { helmetPromosConfig } from '../../data/seo';
import colors from '../../theme/colors';
import TextBlock from '../components/TextBlock';
import {
    acceptanceOfTheBases,
    award,
    awardCeremony,
    dataProtection,
    duration,
    generalConditions,
    howToTakePart,
    modifications,
    organizer,
    qeographicScope,
    winnerSelection,
} from './constants';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 237px)',
        '@media (max-width: 959px)': {
            minHeight: 'calc(100vh - 420px)',
        },
        background: colors.black,
        color: colors.white,
    },
    container: {
        paddingTop: 24,
        paddingBottom: 24,
    },
}));

const Promotions = () => {
    const classes = useStyles();
    return (
        <>
            <Helmet>
                <title>{helmetPromosConfig.title}</title>
                <meta name="description" content={helmetPromosConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetPromosConfig.title} />
                <meta property="og:description" content={helmetPromosConfig.description} />
            </Helmet>
            <Box className={classes.root}>
                <Container maxWidth="xl" className={classes.container}>
                    <Typography
                        variant="h1"
                        align="center"
                        style={{
                            marginBottom: '12px',
                            color: colors.white,
                            fontSize: '20px',
                            lineHeight: '24px',
                        }}
                    >
                        Bases y condiciones del sorteo &quot;Gana una memoria RAM con Gamer Factory.&quot;
                    </Typography>

                    <TextBlock blockTitle="Organizador" blockTexts={organizer} />
                    <TextBlock blockTitle="Ámbito geográfico" blockTexts={qeographicScope} />
                    <TextBlock blockTitle="Duración" blockTexts={duration} />
                    <TextBlock blockTitle="Premio" blockTexts={award} />
                    <TextBlock blockTitle="Cómo participar" blockTexts={howToTakePart} />
                    <TextBlock blockTitle="Selección de los ganadores" blockTexts={winnerSelection} />
                    <TextBlock blockTitle="Entrega del premio" blockTexts={awardCeremony} />
                    <TextBlock blockTitle="Condiciones generales" blockTexts={generalConditions} />
                    <TextBlock blockTitle="Protección de datos" blockTexts={dataProtection} />
                    <TextBlock blockTitle="Modificaciones y/o anexos" blockTexts={modifications} />
                    <TextBlock blockTitle="Aceptación de las bases" blockTexts={acceptanceOfTheBases} />
                </Container>
            </Box>
        </>
    );
};

export default Promotions;
