/* eslint-disable camelcase */
import { ProductTypes } from './types';

export const sendPageViewGAEvent = (page) => {
    try {
        window.dataLayer.push({
            event: 'page_view',
        });
    } catch (error) {
        console.error('Error sending GA event', error, { page });
    }
};

const clearEcommerce = () => {
    window.dataLayer.push({ ecommerce: null });
};

export const sendItemViewGAEvent = ({ item_id, item_name, item_category, price }) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
                items: [
                    {
                        item_id,
                        item_name,
                        item_category,
                        price,
                        index: 0,
                    },
                ],
                currency: 'ARS',
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, {
            item_id,
            item_name,
            item_category,
            price,
        });
    }
};

export const sendAddToCartGAEvent = (items) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: 'ARS',
                value: items.reduce((acc, item) => acc + item.price * (item.quantity || 1), 0),
                items,
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { items });
    }
};

export const sendRemoveFromCartGAEvent = (items) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                currency: 'ARS',
                value: items.reduce((acc, item) => acc + item.price * (item.quantity || 1), 0),
                items,
                index: 0,
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { items });
    }
};

export const sendCartItemQuantityModificationGAEvent = ({ item: { item_id, item_name, item_category, price }, oldQuantity, newQuantity }) => {
    if (oldQuantity < newQuantity) {
        sendAddToCartGAEvent([
            {
                item_id,
                item_name,
                item_category,
                price,
                quantity: newQuantity - oldQuantity,
                index: 0,
            },
        ]);
    } else if (oldQuantity > newQuantity) {
        sendRemoveFromCartGAEvent([
            {
                item_id,
                item_name,
                item_category,
                price,
                quantity: oldQuantity - newQuantity,
                index: 0,
            },
        ]);
    }
};

export const sendAddEasyBuildToCartGAEvent = ({ build, soSelected = null }) => {
    try {
        const itemsForGA = [];
        itemsForGA.push({
            item_id: build.id,
            item_name: build.name,
            item_category: ProductTypes.BUILD,
            price: build.price.special.ARS,
            quantity: 1,
            index: 0,
        });
        if (soSelected) {
            itemsForGA.push({
                item_id: soSelected.id,
                item_name: soSelected.name,
                item_category: ProductTypes.OPERATIVE_SYSTEM,
                price: soSelected.price.special.ARS,
                quantity: 1,
                index: 1,
            });
        }

        sendAddToCartGAEvent(itemsForGA);
    } catch (error) {
        console.error('Error sending GA event', error, { build, soSelected });
    }
};

export const sendEasyBuilderSelectBrandGAEvent = (brand) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'select_content',
            ecommerce: {
                content_type: 'easy_builder_brand',
                content_id: String(brand),
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { brand });
    }
};

export const sendEasyBuilderSelectGamesGAEvent = (games) => {
    try {
        if (!Array.isArray(games)) return;
        clearEcommerce();
        window.dataLayer.push({
            event: 'select_item',
            ecommerce: {
                item_list_id: 'easy_builder_games',
                items: games.map((game) => ({
                    item_id: game.id,
                    item_name: game.name,
                })),
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { games });
    }
};

export const sendEasyBuilderSelectBudgetGAEvent = (budget) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'select_content',
            ecommerce: {
                content_type: 'easy_builder_budget',
                content_id: String(budget),
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { budget });
    }
};

export const sendAddPaymentInfoGAEvent = ({ value, paymentType, items }) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'add_payment_info',
            ecommerce: {
                currency: 'ARS',
                value,
                payment_type: paymentType,
                items,
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { value, paymentType, items });
    }
};

export const sendPurchaseGAEvent = ({ value, items, orderId }) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                currency: 'ARS',
                value,
                items,
                transaction_id: orderId,
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { value, items, orderId });
    }
};

export const sendPurchaseFailureGAEvent = ({ value, items, errorCode }) => {
    try {
        clearEcommerce();
        window.dataLayer.push({
            event: 'purchase_failure',
            ecommerce: {
                currency: 'ARS',
                value,
                items,
                error_code: errorCode,
            },
        });
    } catch (error) {
        console.error('Error sending GA event', error, { value, items, errorCode });
    }
};
