import { useEffect } from 'react';

import ProBuilderProductList from './ProBuilderProductList';

const BaseProductMultiSelect = ({
    buildProduct,
    validateProducts,
    buildSpecs,
    updateBuildSpecs,
    validateNextStepAllowed,
    productType,
    peripherals = false,
    validateQuantity,
    defaultProduct = null,
}) => {
    const productTypeKey = peripherals ? 'peripherals' : productType;

    const selectProduct = (product) => {
        const productIndex = buildProduct.findIndex((element) => element.id === product.id);
        const newSelectedProducts = [...buildProduct];

        if (productIndex !== -1) {
            newSelectedProducts[productIndex] = {
                ...newSelectedProducts[productIndex],
                quantity: product.quantity,
            };
        } else {
            newSelectedProducts.push(product);
        }

        if (peripherals || validateProducts?.(newSelectedProducts)) {
            updateBuildSpecs({ [productTypeKey]: newSelectedProducts });
        }
    };

    const deselectProduct = (product) => {
        const productIndex = buildProduct.findIndex((element) => element.id === product.id);
        if (productIndex !== -1) {
            const newSelectedProducts = [...buildProduct];
            newSelectedProducts.splice(productIndex, 1);
            validateProducts?.(newSelectedProducts);
            updateBuildSpecs({ [productTypeKey]: newSelectedProducts });
        }
    };

    const productSpecs = buildSpecs[productType];
    useEffect(() => validateNextStepAllowed(), [validateNextStepAllowed, productSpecs]);

    return (
        <ProBuilderProductList
            buildProduct={buildProduct}
            multiSelect
            selectProduct={selectProduct}
            deselectProduct={deselectProduct}
            defaultProduct={defaultProduct}
            productType={productType}
            buildSpecs={buildSpecs}
            validateQuantity={validateQuantity}
            peripherals={peripherals}
        />
    );
};

export default BaseProductMultiSelect;
