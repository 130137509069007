import pc1 from '../resources/checkout/components/pc1.png';

const emptyImage = '/images/emptyImage.png';

const convertPhotoToBase64 = (file) =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ base64: reader.result });
    });

const getProductImageSrc = (product) => product?.image ?? product?.images?.[0]?.url ?? (product?.buildId && pc1) ?? emptyImage;
const getProductImageAlt = (product) => product?.image ?? product?.images?.[0]?.title ?? 'Product Image';

const imageHelper = {
    convertPhotoToBase64,
    getProductImageSrc,
    getProductImageAlt,
    emptyImage,
};

export default imageHelper;
