import { makeStyles } from '@material-ui/core';

export const useTreeItemStyles = makeStyles(() => ({
    content: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        color: '#727272',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '1px 0',
    },
    labelText: {
        fontWeight: 'inherit',
    },
}));

export const useOrderSelectStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: '7px',
        marginRight: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 120,
        '@media (max-width: 372px)': {
            alignItems: 'left',
            flexDirection: 'column',
        },
    },
    orderSelectTitle: {
        '@media (max-width: 372px)': {
            width: '100%',
            marginLeft: '4px',
        },
    },
    sortSelect: {
        marginTop: 0,
        fontSize: 12,
        fontWeight: 'bold',
    },
    selectInput: {
        marginLeft: 4,
        paddingBottom: 4,
    },
}));

export const useMobileFiltersDropdownButtonStyles = makeStyles(() => ({
    mobileFilterContainer: {
        backgroundColor: '#F1F1F1',
    },
    filterIcon: {
        width: 16,
        margin: 0,
        padding: 0,
    },
    filterLabel: {
        fontSize: 14,
        marginTop: 3,
        marginLeft: 5,
        fontWeight: 600,
    },
}));

export const useFilterButtonsStyles = makeStyles(() => ({
    filterSpecButton: {
        justifyContent: 'flex-start',
        margin: 0,
        padding: '1px 0',
        color: '#727272',
        fontWeight: '300',
        textTransform: 'none',
        fontSize: '14px',
    },
}));

export const useMobileFiltersStyles = makeStyles(() => ({
    containerMobile: {
        width: '100vw',
        backgroundColor: '#E5E5E5',
    },
}));

export const useProductFilterByTypeStyles = makeStyles(() => ({
    filterContainer: {
        backgroundColor: '#E5E5E5',
        minHeight: '65vh',
        marginBottom: '40px',
    },
    priceInput: {
        backgroundColor: '#E5E5E5',
        width: '75px',
        height: '30px',
        fontSize: '12px',
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    bottomContainer: {
        minHeight: '10vh',
    },
}));

export const useBrandsStyles = makeStyles(() => ({
    formControl: {
        marginLeft: 3,
    },
    checkbox: {
        backgroundColor: '#F1F1F1',
        fontSize: 1,
    },
    typography: {
        marginTop: 10,
        marginLeft: 5,
        marginBottom: 8,
        textTransform: 'capitalize',
        color: '#727272',
    },
}));
