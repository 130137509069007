import { Box, Button, Dialog, DialogContent, FormControl, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';

import API from '../../api/API';
import { UserContext } from '../../contexts';
import handler from '../../helpers/handler';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles(() => ({
    register: {
        margin: 'auto',
        maxWidth: 302,
    },
    emailInput: {
        marginTop: '31px',
    },
    closeIcon: {
        color: '#727272',
    },
}));

const ForgotPasswordModal = ({ open, setOpen, openLoginModal }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [, dispatch] = useContext(UserContext);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        API.auth
            .forgotPassword({ email })
            .then(() => {
                handleClose();
                enqueueSnackbar('Enviaremos las instrucciones de recuperación de tu cuenta al correo electrónico', { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar('Hubo un problema intentando recuperar tu cuenta. Por favor intentá nuevamente en unos minutos', { variant: 'warning' });
                return handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleChangeEmail = (event) => setEmail(event.target.value);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ square: true }} fullWidth maxWidth="xs">
                <DialogContent>
                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                        <Button onClick={handleClose} className={classes.closeIcon}>
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h4">Olvidé mi contraseña</Typography>
                    </Box>

                    <Box textAlign="center">
                        <Box className={classes.register}>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        InputProps={{
                                            className: classes.emailInput,
                                        }}
                                        onChange={handleChangeEmail}
                                    />
                                </FormControl>
                                <Box mt={4} mb={2}>
                                    <FormControl fullWidth>
                                        <LoadingButton variant="contained" type="submit" loading={loading}>
                                            RECUPERAR CONTRASEÑA
                                        </LoadingButton>
                                        <Typography variant="caption">Enviaremos un correo electrónico con las instrucciones para recuperar tu cuenta.</Typography>
                                    </FormControl>
                                </Box>
                            </form>
                        </Box>
                        {openLoginModal ? (
                            <Box mb={2}>
                                <Typography variant="subtitle1" align="center">
                                    {'volver a '}
                                    <Button variant="text" onClick={openLoginModal}>
                                        INICIAR SESIÓN
                                    </Button>
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ForgotPasswordModal;
