import { CpuBrands, CpuSockets } from '../types';
import boolean from './boolean';

const brandTypes = [
    { id: CpuBrands.INTEL, name: 'Intel' },
    { id: CpuBrands.AMD, name: 'AMD' },
];

const socketTypes = [
    { id: CpuSockets.AM4, name: 'AMD AM4' },
    { id: CpuSockets.AM5, name: 'AMD AM5' },
    { id: CpuSockets.TRX4, name: 'AMD TRX4' },
    { id: CpuSockets.LGA1151, name: 'Intel 1151' },
    { id: CpuSockets.LGA1200, name: 'Intel 1200' },
    { id: CpuSockets.LGA1700, name: 'Intel 1700' },
    { id: CpuSockets.LGA2011, name: 'Intel 2011' },
];

const modelTypes = [
    { id: 'i3', name: 'Core I3' },
    { id: 'i5', name: 'Core I5' },
    { id: 'i7', name: 'Core I7' },
    { id: 'i9', name: 'Core I9' },
    { id: 'athlon', name: 'Athlon' },
    { id: 'ryzen3', name: 'Ryzen 3' },
    { id: 'ryzen5', name: 'Ryzen 5' },
    { id: 'ryzen7', name: 'Ryzen 7' },
    { id: 'ryzen9', name: 'Ryzen 9' },
];

export default {
    brand: brandTypes,
    igpu: boolean,
    has_cooler: boolean,
    model: modelTypes,
    socket: socketTypes,
};
