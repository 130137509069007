import { Button, CircularProgress, makeStyles } from '@material-ui/core';

import colors from '../theme/colors';

const useStyles = makeStyles(() => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        color: colors.black,
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
const LoadingButton = ({ loading, children, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <Button {...props} disabled={props.disabled || loading}>
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};

export default LoadingButton;
