import { Box, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@material-ui/lab/TreeView';

import StyledTreeItem from './StyledTreeItem';
import { useFilterButtonsStyles } from './styles';

const FilterButtons = ({ types, setSelectedType }) => {
    const classes = useFilterButtonsStyles();
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            {types.map((component, index) =>
                component.subTypes ? (
                    <TreeView key={component.title} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                        <StyledTreeItem nodeId={`${index}`} label={component.title}>
                            {component.subTypes.map((subComponent) => (
                                <StyledTreeItem key={subComponent.title} onClick={() => setSelectedType(subComponent)} label={subComponent.title} />
                            ))}
                        </StyledTreeItem>
                    </TreeView>
                ) : (
                    <Button key={component.title} onClick={() => setSelectedType(component)} className={classes.filterSpecButton}>
                        {component.title}
                    </Button>
                )
            )}
        </Box>
    );
};

export default FilterButtons;
