import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { countBy, uniqBy } from 'lodash';

import textHelpers from '../../../../../helpers/textHelpers';

const useStyles = makeStyles(() => ({
    specBox: {
        borderBottom: '1px solid',
        borderBottomColor: '#DBDBD5',
        paddingBottom: 17,
    },
    specTitle: {
        fontWeight: 700,
        paddingTop: 10,
    },
    specData: {
        paddingRight: 12,
    },
    leftSpec: {
        paddingLeft: 16,
        borderLeft: '1px solid',
        borderLeftColor: '#DBDBD5',
        '@media (max-width: 769px)': {
            borderLeft: 'none',
            paddingLeft: 0,
        },
    },
}));

const ProductsInBuild = ({ products }) => {
    const classes = useStyles();
    const showProducts = (productName) => {
        const uniqueProducts = uniqBy(products[productName], 'id');
        const productsQuantity = countBy(products[productName], 'id');
        return (
            <>
                {uniqueProducts.length > 0 ? (
                    uniqueProducts.map((product) => (
                        <Typography key={product.id} className={classes.specData} variant="subtitle1" align="left">
                            {product.name} (x {productsQuantity[product.id]})
                        </Typography>
                    ))
                ) : (
                    <Typography className={classes.specData} variant="subtitle1" align="left">
                        N/A
                    </Typography>
                )}
            </>
        );
    };
    return (
        <>
            <Box mt={2}>
                <Grid container>
                    <Grid item xs={12} sm={6} className={classes.specBox}>
                        <Box display="flex">
                            <Typography className={classes.specTitle} variant="subtitle1">
                                Sistema Operativo:
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Typography className={classes.specData} variant="subtitle1" align="left">
                                Windows 11
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className={`${classes.specBox} ${classes.leftSpec}`}>
                        <Box display="flex">
                            <Typography className={classes.specTitle} variant="subtitle1">
                                Memoria:
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Typography className={classes.specData} variant="subtitle1" align="left">
                                {Array.isArray(products.ram) ? showProducts('ram') : textHelpers?.cleanDescription(products.ram)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.specBox}>
                        <Box display="flex">
                            <Typography className={classes.specTitle} variant="subtitle1">
                                Procesador
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Typography className={classes.specData} variant="subtitle1" align="left">
                                {Array.isArray(products.cpu) ? showProducts('cpu') : textHelpers?.cleanDescription(products.cpu)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className={`${classes.specBox} ${classes.leftSpec}`}>
                        <Box display="flex">
                            <Typography className={classes.specTitle} variant="subtitle1">
                                Placa madre:
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Typography className={classes.specData} variant="subtitle1" align="left">
                                {Array.isArray(products.mother) ? showProducts('mother') : textHelpers?.cleanDescription(products.mother)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.specBox}>
                            <Grid container>
                                <Typography className={classes.specTitle} variant="subtitle1">
                                    Placa de video
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Typography className={classes.specData} variant="subtitle1" align="left">
                                    {Array.isArray(products.gpu) ? showProducts('gpu') : textHelpers?.cleanDescription(products.gpu)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} className={`${classes.specBox} ${classes.leftSpec}`}>
                            <Grid container>
                                <Typography className={classes.specTitle} variant="subtitle1">
                                    Almacenamiento:
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Typography className={classes.specData} variant="subtitle1" align="left">
                                    {Array.isArray(products.storage) ? showProducts('storage') : textHelpers?.cleanDescription(products.storage)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ProductsInBuild;
