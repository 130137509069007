import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import API from '../../../api/API';
import { UserContext, useUser } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import cartHelper from '../../../helpers/cartHelper';
import handler from '../../../helpers/handler';
import proBuildHelper from '../../../helpers/proBuildHelper';
import LoadingButton from '../../LoadingButton';
import DropdownCartItem from './DropdownCartItem';

const useStyles = makeStyles((theme) => ({
    divider: {
        background: theme.palette.primary.main,
    },
    fontCarrito: {
        fontWeight: 700,
        fontSize: '16px',
        paddingBottom: '10px',
        paddingTop: '23px',
    },
    fontTotal: {
        fontWeight: 300,
        fontSize: '16px',
    },
    fontPrecio: {
        fontWeight: 700,
        fontSize: '16px',
    },
    gridContainer: {
        justifyContent: 'space-between',
    },
    scrollView: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 270px)',
        '&::-webkit-scrollbar': {
            width: '0.3em',
            paddingLeft: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(0,0,0,.4)',
        },
    },
    container: {
        background: '#F8F9FB',
    },
}));

const DropdownCart = () => {
    const classes = useStyles();
    const [state, dispatch] = useContext(UserContext);
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(false);
    const products = proBuildHelper.formatProductsWithBuilds(cart.items);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (state.user?.cart.id && state.user?.cart.items) {
            setCart(state.user.cart);
        } else {
            API.carts
                .post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((error) => {
                    handler.handleError({
                        error,
                        userContextDispatch: dispatch,
                        enqueueSnackbar,
                    });
                });
        }
    }, [state, dispatch, enqueueSnackbar]);

    const {
        cart: { clear },
    } = useUser();

    const clearCart = () => {
        setLoading(true);
        clear().finally(() => setLoading(false));
    };

    return (
        <div className={classes.container}>
            <Box px={3} display="flex" justifyContent="space-between" alignContent="center">
                <Typography variant="h6" className={classes.fontCarrito}>
                    Carrito
                </Typography>
                <LoadingButton loading={loading} variant="text" onClick={clearCart}>
                    VACIAR CARRITO
                </LoadingButton>
            </Box>

            <div className={classes.scrollView}>
                {products.map((item, index) => {
                    const keyID = item.id + index;
                    return <DropdownCartItem key={keyID} item={item} setLoading={setLoading} loading={loading} index={index} />;
                })}
            </div>

            <Divider classes={{ root: classes.divider }} />

            <Box mt={2} mb={3} px={3}>
                <Grid container className={classes.gridContainer}>
                    <Grid item>
                        <Typography variant="h6" className={classes.fontTotal}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid item>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant="h6" className={classes.fontPrecio}>
                                {cartHelper.formatPrice(cart.totalPrice?.special)}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Box pb={3} px={3} display="flex" justifyContent="center">
                <Button variant="contained" component={Link} to="/completeOrder">
                    Finalizar compra
                </Button>
            </Box>
        </div>
    );
};

export default DropdownCart;
