import { CoolerTypes } from '../types';
import boolean from './boolean';

const types = [
    { id: CoolerTypes.AIR_COOLER, name: 'Air Cooler' },
    { id: CoolerTypes.WATER_COOLER, name: 'Water Cooler' },
];

const lighting = [
    { id: 'argb', name: 'ARGB' },
    { id: 'rgb', name: 'RGB' },
    { id: 'none', name: 'Sin iluminación' },
];

const fanSizes = [
    { id: '92mm', name: '92mm' },
    { id: '120mm', name: '120mm' },
    { id: '140mm', name: '140mm' },
    { id: 'dual_tower', name: 'Dual tower' },
]

export default {
    fanSize: fanSizes,
    lighting: lighting,
    cooler_type: types,
    screen: boolean,
};
