import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import cartHelper from '../../helpers/cartHelper';
import imageHelper from '../../helpers/imageHelper';
import colors from '../../theme/colors';
import gameCheckbox from '../pro-builder/images/gameCheckbox.svg';
import ModalPreViewProduct from './ModalPreViewProduct';
import ProductQuantitySelection from './ProductQuantitySelection';

const useStyles = makeStyles(() => ({
    cardWrapper: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
    },
    card: {
        width: '100%',
        position: 'relative',
    },
    mediaWrapper: {
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
    },
    addProduct: {
        width: '100%',
    },
    removeProduct: {
        width: '100%',
        backgroundColor: colors.black,
        color: colors.yellow,
    },
    productTitleWrapper: {
        display: '-webkit-box',
        boxOrient: 'vertical',
        lineClamp: 2,
        wordBreak: 'keep-all',
        overflow: 'hidden',
        minHeight: 39,
        textAlign: 'center',
    },
    productTitle: {
        color: colors.blackGrey,
        fontSize: 13,
    },
    checked: {
        position: 'relative',
        height: 'auto',
        '&::after': {
            content: `url(${gameCheckbox})`,
            width: 25,
            height: 26,
            display: 'block',
            position: 'absolute',
            right: -12,
            top: -13,
        },
        '& .MuiPaper-root': {
            boxShadow: '0px 2px 1px -1px #f8e837, 0px 1px 1px 0px #f8e837, 0px 1px 3px 0px #f8e837',
        },
    },
    label: {
        display: 'inline-block',
        background: colors.yellow,
        position: 'absolute',
        left: 0,
        width: 'auto',
        zIndex: 1,
    },
    strikethroughPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        color: colors.blackGrey,
    },
    cardContent: {
        padding: 0,
    },
}));

const ProductCard = ({
    product,
    highlightWhenSelected,
    isSelected,
    multiSelect,
    handleChangeQuantity,
    selectedQuantity,
    handleAddProduct,
    handleRemoveProduct,
    usePreviewInsteadOfProductDetail,
    showProductDetailLink = false,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [modalPreViewProduct, setModalPreViewProduct] = useState(false);

    const handleClick = () => {
        if (usePreviewInsteadOfProductDetail) setModalPreViewProduct(true);
        else history.push(`/producto/${encodeURIComponent(product.slug)}`);
    };

    const toggleSelection = () => {
        if (!isSelected) handleAddProduct(product);
        else handleRemoveProduct(product);
    };

    const handleMediaClick = () => {
        setModalPreViewProduct(true);
    };

    return (
        <>
            <Box className={`${classes.cardWrapper} ${highlightWhenSelected && isSelected && classes.checked}`}>
                <Card className={classes.card}>
                    {product.label && (
                        <Box mt={2} px={2} py={1} className={classes.label}>
                            {product.label}
                        </Box>
                    )}
                    <CardContent className={classes.cardContent}>
                        <CardActionArea onClick={handleMediaClick}>
                            <Box className={classes.mediaWrapper}>
                                <CardMedia
                                    component="img"
                                    alt={imageHelper.getProductImageAlt(product)}
                                    className={classes.media}
                                    image={imageHelper.getProductImageSrc(product)}
                                    title={product.name.toUpperCase()}
                                />
                            </Box>
                        </CardActionArea>
                        <Box my={1} mx={2} className={classes.productTitleWrapper}>
                            <Typography className={classes.productTitle}>{product.name.toUpperCase()}</Typography>
                        </Box>
                        <Box my={1} display="flex" justifyContent="center">
                            {product.price.special.strikethrough && (
                                <Typography variant="h6" className={classes.strikethroughPrice}>
                                    {cartHelper.formatPrice(product.price.special.strikethrough)}
                                </Typography>
                            )}
                            <Typography variant="h5">{cartHelper.formatPrice(product.price.special.ARS)}</Typography>
                        </Box>
                    </CardContent>
                    <CardActions style={{ padding: '0px 22px', display: 'block' }}>
                        <Box display="flex" justifyContent="center" flexDirection="column">
                            <Box display="block" pb={1} textAlign="center">
                                {multiSelect && <ProductQuantitySelection onQuantityChanged={handleChangeQuantity} selectedQuantity={selectedQuantity} maxQuantity={product.stock} />}

                                <Button className={isSelected ? classes.removeProduct : classes.addProduct} onClick={toggleSelection} variant="contained" style={{ minWidth: 0 }}>
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {isSelected ? (selectedQuantity > 1 ? `Agregado (${selectedQuantity})` : 'Agregado') : 'Agregar'}
                                </Button>
                                <Button onClick={handleClick} fullWidth variant="text">
                                    {usePreviewInsteadOfProductDetail ? 'Vista rápida' : 'Ver producto'}
                                </Button>
                            </Box>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
            <ModalPreViewProduct
                handleAddProduct={handleAddProduct}
                handleChangeQuantity={handleChangeQuantity}
                isSelected={isSelected}
                multiSelect={multiSelect}
                open={modalPreViewProduct}
                product={product}
                selectedQuantity={selectedQuantity}
                setOpen={setModalPreViewProduct}
                showProductDetailLink={showProductDetailLink}
            />
        </>
    );
};

export default ProductCard;
