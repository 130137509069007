import boolean from './boolean';

export const cablingOptions = [
    { id: 'fijo', name: 'Fijo' },
    { id: 'modular', name: 'Modular' },
];

export const certification80PlusOptions = [
    { id: 'bronze', name: 'Bronze' },
    { id: 'gold', name: 'Gold' },
    { id: 'platinum', name: 'Platinum' },
    { id: 'titanium', name: 'Titanium' },
]

export const formats = [
    { id: 'ATX', name: 'ATX' },
    { id: 'SFX', name: 'SFX' },
];

export default {
    cabling: cablingOptions,
    certification80Plus: certification80PlusOptions,
    format: formats,
    lighting: boolean,
};
