/* eslint-disable camelcase */
import types from '../helpers/types';

const accessories = {
    specs: {},
};

const cpu_case = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        mother_form_factor: {
            name: 'Tipo placa madre',
            values: [],
            type: 'mother_form_factor',
        },
        form_factor: {
            name: 'Form factor',
            values: [],
            type: 'form_factor',
        },
        width: {
            name: 'Ancho',
            values: [],
            type: 'width',
        },
        fan_slots_120: {
            name: 'Ranuras ventilador 120',
            values: [],
            type: 'fan_slots_120',
        },
        fan_slots_140: {
            name: 'Ranuras ventilador 140',
            values: [],
            type: 'fan_slots_140',
        },
        fan_slots_240: {
            name: 'Ranuras ventilador 240',
            values: [],
            type: 'fan_slots_240',
        },
        fan_slots_280: {
            name: 'Ranuras ventilador 280',
            values: [],
            type: 'fan_slots_280',
        },
        fan_slots_360: {
            name: 'Ranuras ventilador 360',
            values: [],
            type: 'fan_slots_360',
        },
        radiator_support: {
            name: 'Soporte para radiador',
            values: [],
            type: 'radiator_support',
        },
    },
};

const case_fan = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        width: {
            name: 'Ancho (cm)',
            values: [types.CaseFanSlotWidths.W_120, types.CaseFanSlotWidths.W_140],
            type: 'width',
        },
    },
};

const chair = {
    specs: {},
};

const cpu = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        socket: {
            name: 'Socket',
            values: [],
            type: 'socket',
        },
    },
};

const cpu_cooler = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        case_radiator: {
            name: 'Radiador en el gabinete',
            values: [],
            type: 'case_radiator',
        },
        case_radiator_width: {
            name: 'Ancho del radiador en el gabinete',
            values: [],
            type: 'case_radiator_width',
        },
        cooler_type: {
            name: 'Tipo',
            values: [types.CoolerTypes.AIR_COOLER, types.CoolerTypes.WATER_COOLER],
            type: 'cooler_type',
        },
    },
};

const desks = {
    specs: {},
};

const furniture = {
    specs: {},
};

const storage = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        connection: {
            name: 'Connection',
            values: [types.StorageConnections.M2, types.StorageConnections.SATA, types.StorageConnections.USB],
            type: 'connection',
        },
        storage_type: {
            name: 'Storage Type',
            values: [types.StorageTypes.HDD, types.StorageTypes.SSD],
            type: 'storage_type',
        },
    },
};

const headset = {
    specs: {},
};

const keyboard = {
    specs: {},
};

const monitor = {
    specs: {
        connections: {
            name: 'Conexiones',
            values: [],
            type: 'connections',
        },
        inches: {
            name: 'Pulgadas',
            values: [],
            type: 'inches',
        },
        refresh_rate: {
            name: 'Tasa de Refresco',
            values: [],
            type: 'refresh_rate',
        },
        resolution: {
            name: 'Resolución',
            values: [],
            type: 'resolution',
        },
    },
};

const mother = {
    specs: {
        brand: {
            name: 'Marca',
            values: [],
            type: 'brand',
        },
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        ram_stick_type: {
            name: 'Tipo de memoria RAM',
            values: [],
            type: 'ram_stick_type',
        },
        ram_slots: {
            name: 'Cantidad Ranuras para RAM',
            values: [],
            type: 'ram_slots',
        },
        pci_express_1x_slot: {
            name: 'Ranuras PCI Express 1x',
            values: [],
            type: 'pci_express_1x_slot',
        },
        pci_express_2x_slot: {
            name: 'Ranuras PCI Express 2x',
            values: [],
            type: 'pci_express_2x_slot',
        },
        pci_express_3x_slot: {
            name: 'Ranuras PCI Express 3x',
            values: [],
            type: 'pci_express_3x_slot',
        },
        pci_express_4x_slot: {
            name: 'Ranuras PCI Express 4x',
            values: [],
            type: 'pci_express_4x_slot',
        },
        pci_express_8x_slot: {
            name: 'Ranuras PCI Express 8x',
            values: [],
            type: 'pci_express_8x_slot',
        },
        pci_express_16x_slots: {
            name: 'Ranuras PCI Express 16x',
            values: [],
            type: 'pci_express_16x_slot',
        },
        sata3_slots: {
            name: 'Ranuras SATA3',
            values: [],
            type: 'sata3_slots',
        },
        m2_slots: {
            name: 'Ranuras M2',
            values: [],
            type: 'm2_slots',
        },
        cpu_socket: {
            name: 'Socket CPU',
            values: [],
            type: 'cpu_socket',
        },
        form_factor: {
            name: 'Form factor',
            values: [],
            type: 'form_factor',
        },
    },
};

const mouse = {
    specs: {},
};

const operativeSystem = {
    specs: {},
};

const other = {
    specs: {},
};

const power = {
    specs: {
        watts_supported: {
            name: 'Watts soportados',
            values: [],
            type: 'watts_supported',
        },
    },
};

const ram = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        capacity: {
            name: 'Capacidad',
            values: [],
            type: 'capacity',
        },
        quantity: {
            name: 'Cantidad',
            values: [],
            type: 'quantity',
        },
        stick_type: {
            name: 'Tipo de ranura',
            values: [types.RamStickTypes.DDR_3, types.RamStickTypes.DDR_4, types.RamStickTypes.DDR_5],
            type: 'stick_type',
        },
        cl: {
            name: 'Latencia CL',
            values: [],
            type: 'cl',
        },
        clock_frequency: {
            name: 'Frecuencia de reloj',
            values: [],
            type: 'clock_frequency',
        },
    },
};

const speakers = {
    specs: {},
};

const gpu = {
    specs: {
        watts: {
            name: 'Watts',
            values: [],
            type: 'watts',
        },
        width: {
            name: 'Ancho',
            values: [],
            type: 'width',
        },
    },
};

const specs = {
    accessories,
    cpu_case,
    case_fan,
    chair,
    cpu,
    cpu_cooler,
    desks,
    furniture,
    storage,
    headset,
    gpu,
    keyboard,
    monitor,
    mother,
    mouse,
    operativeSystem,
    other,
    power,
    ram,
    speakers,
};

export default specs;
