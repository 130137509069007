import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        width: 'fit-content',
        height: 'fit-content',
        minHeight: '40px',
        background: '#C4C4C4',
        padding: '15px 0px',
    },
    title: {
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '16.8px',
        textAlign: 'center',
    },
}));

const WithoutStockBanner = ({ sxContainer, withCryingFace = false }) => {
    const classes = useStyles();
    return (
        <Box className={classes.container} sx={sxContainer} minWidth={withCryingFace ? '240px' : '223px'}>
            <Typography className={classes.title}>
                TEMPORALMENTE AGOTADO
                {withCryingFace && " :'("}
            </Typography>
        </Box>
    );
};

export default WithoutStockBanner;
