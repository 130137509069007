import { ProductTypes } from '../../../../helpers/types';
import BaseProductMultiSelect from '../BaseProductMultiSelect';

const CaseFanSelect = ({ updateBuildSpecs, buildSpecs, setGoToNextAllowed }) => {
    const validateNextStepAllowed = () => setGoToNextAllowed(true);
    const validateCaseFans = () => true;

    return (
        <>
            <BaseProductMultiSelect
                buildProduct={buildSpecs.case_fan}
                validateProducts={validateCaseFans}
                buildSpecs={buildSpecs}
                updateBuildSpecs={updateBuildSpecs}
                validateNextStepAllowed={validateNextStepAllowed}
                productType={ProductTypes.CASE_FAN}
            />
        </>
    );
};

export default CaseFanSelect;
