import { createContext, useEffect, useReducer } from 'react';

import { useUser } from '..';
import proBuildHelper from '../../helpers/proBuildHelper';
import productsReducer from './reducer';

export const ProductsContext = createContext();

const isLocalStorageDataValid = (localProductsParsed) => {
    const localProductsEntries = Object.entries(localProductsParsed);
    const initialStateKeys = Object.keys(proBuildHelper.buildInitialState);

    const allKeysExistsOnInitialData = localProductsEntries.every(([key]) => initialStateKeys.some((initialKey) => key === initialKey));
    const localDataHasAllKeys = initialStateKeys.every((key) => localProductsEntries.some(([localKey]) => key === localKey));
    if (!allKeysExistsOnInitialData || !localDataHasAllKeys) return false;

    return localProductsEntries.every(([key, value]) => {
        if (Array.isArray(value)) {
            return Array.isArray(proBuildHelper.buildInitialState[key]);
        }
        return !Array.isArray(proBuildHelper.buildInitialState[key]);
    });
};

const ProductsContextProvider = ({ children }) => {
    const { handleLogout } = useUser();

    const [productsProBuilder, productsDispatch] = useReducer(productsReducer, proBuildHelper.buildInitialState, () => {
        const localProducts = localStorage.getItem('products');
        if (!localProducts) return proBuildHelper.buildInitialState;
        try {
            const localProductsParsed = JSON.parse(localProducts);

            const isDataValid = isLocalStorageDataValid(localProductsParsed);
            if (isDataValid) return localProductsParsed;

            handleLogout();
            return proBuildHelper.buildInitialState;
        } catch (error) {
            handleLogout();
            return proBuildHelper.buildInitialState;
        }
    });

    useEffect(() => {
        localStorage.setItem('products', JSON.stringify(productsProBuilder));
    }, [productsProBuilder]);

    return <ProductsContext.Provider value={{ productsProBuilder, productsDispatch }}>{children}</ProductsContext.Provider>;
};

export default ProductsContextProvider;
