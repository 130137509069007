import boolean from './boolean';

const connectivity = [
    { id: 'wired', name: 'Cableado' },
    { id: 'wireless', name: 'Inalámbrico' },
];

const connectionType = [
    { id: 'bluetooth', name: 'Bluetooth' },
    { id: 'jack', name: 'Jack 3.5mm' },
    { id: 'usb', name: 'USB' },
];

const lighting = [
    { id: 'argb', name: 'ARGB' },
    { id: 'rgb', name: 'RGB' },
    { id: 'fixedColor', name: 'Color Fijo' },
    { id: 'noBacklight', name: 'Sin retroiluminación' },
];

const sound = [
    { id: '5.1', name: '5.1' },
    { id: '7.1', name: '7.1' },
    { id: '2.0', name: '2.0' },
];

const format = [
    { id: 'headset', name: 'Headset' },
    { id: 'in-ear', name: 'In-ear' },
];

export default {
    connectivity,
    connectionType,
    withMicrophone: boolean,
    lighting,
    sound,
    noiseCancelling: boolean,
    format,
};
