const types = [
    { id: 'dimm', name: 'DIMM' },
    { id: 'sodimm', name: 'SODIMM' },
];

const capacities = [
    { id: '8gb', name: '8 GB' },
    { id: '16gb', name: '16 GB' },
    { id: '24gb', name: '24 GB' },
    { id: '32gb', name: '32 GB' },
    { id: '64gb', name: '64 GB' },
    { id: '128gb', name: '128 GB' },
];

const stickTypes = [
    { id: 'ddr4', name: 'DDR4' },
    { id: 'ddr5', name: 'DDR5' },
];

const clockFrequencyUnits = [
    { id: 'mhz', name: 'MHz' },
    { id: 'mts', name: 'MT/s' },
];

export default {
    type: types,
    capacity: capacities,
    stick_type: stickTypes, // FIXME: usar camelCase en vez de snake_case
    clockFrequencyUnit: clockFrequencyUnits,
};
