import GoogleMapReact from 'google-map-react';

import { DEFAULT_CENTER } from '../../../../common/constants';

const ShippingAgencyMap = ({ center, zoom, markers }) => (
    <div
        style={{
            height: '384px',
            width: '100%',
            '@media (max-width: 959px)': {
                height: '0px',
                width: '0px',
            },
        }}
    >
        <GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_TOKEN }} center={center} defaultCenter={DEFAULT_CENTER} defaultZoom={4} resetBoundsOnResize zoom={zoom}>
            {markers}
        </GoogleMapReact>
    </div>
);

export default ShippingAgencyMap;
