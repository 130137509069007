import { useSnackbar } from 'notistack';
import { useState } from 'react';

import ProductCard from './ProductCard';

const validateStock = (numberOfProducts, stock) => {
    if (numberOfProducts > stock) throw new Error(`Disculpá, solo tenemos ${stock} unidad/es en stock`);
};

const ProBuilderProductCard = ({ checked, product, productQuantity, updateProductQuantity, selectProduct, multiSelect = false, deselectProduct, validateQuantity }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isSelected, setSelected] = useState(checked);

    const handleChangeQuantity = async (valueToAdd) => {
        let numberOfProducts = valueToAdd + productQuantity;
        numberOfProducts = numberOfProducts < 0 ? 0 : numberOfProducts;

        try {
            validateStock(numberOfProducts, product.stock);
            validateQuantity?.({
                product,
                quantity: numberOfProducts * (product.specs?.quantity || 1),
            });
            updateProductQuantity(product.id, numberOfProducts);
            if (isSelected) {
                selectProduct({ ...product, quantity: numberOfProducts });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'warning' });
        }
    };

    const toggleProductSelection = () => {
        const productWithQuantity = product;
        productWithQuantity.quantity = productQuantity;
        if (isSelected) {
            deselectProduct(product);
        } else {
            selectProduct(productWithQuantity);
            if (multiSelect) {
                updateProductQuantity(product.id, productQuantity);
            }
        }
        setSelected(true);
    };

    return (
        <ProductCard
            handleAddProduct={toggleProductSelection}
            handleRemoveProduct={toggleProductSelection}
            handleChangeQuantity={handleChangeQuantity}
            isSelected={isSelected}
            multiSelect={multiSelect}
            product={product}
            highlightWhenSelected
            selectedQuantity={productQuantity}
            usePreviewInsteadOfProductDetail
        />
    );
};

export default ProBuilderProductCard;
