import { ProductTypes } from '../helpers/types';
import desktop from './icons/desktop.svg';

// eslint-disable-next-line import/prefer-default-export
export const pcGamer = {
    type: ProductTypes.BUILD,
    icon: desktop,
    image: desktop,
    title: 'Pc Gamer',
};
