import notebookIcon from '../../data/icons/notebook.svg';
import offersIcon from '../../data/icons/offers.svg';
import { ProductTypes } from '../../helpers/types';

export const maxAndMinState = {
    max: null,
    min: null,
};

export const selectedInitialValues = {
    brand: null,
    spec: null,
    specType: null,
    deleteAll: true,
};

export const notebooksType = {
    type: ProductTypes.NOTEBOOKS,
    icon: notebookIcon,
    image: notebookIcon,
    title: 'Notebooks',
};

export const offersType = {
    type: 'ofertas', // It's not a ProductType
    icon: offersIcon,
    image: offersIcon,
    title: 'Ofertas',
};

export const offersRoute = '/ofertas';
export const productsRoute = '/productos';
