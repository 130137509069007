import API from '../api/API';
import userConstants from '../contexts/User/userConstants';
import storageHelper from './storageHelper';

function logout(userContextDispatch) {
    API.auth.logout();
    userContextDispatch({ type: userConstants.LOGOUT });
    storageHelper.logout();
}

function handleError({ customErrorMessage, enqueueSnackbar, error, history, redirectIfNotFound = false, redirectToHome, userContextDispatch }) {
    let message = '';
    if (error?.response?.status === 401) {
        enqueueSnackbar('Su sesión ha caducado. Vuelva a iniciar sesión.', { variant: 'warning' });
        logout(userContextDispatch);
        if (redirectToHome) history.push('/');
    } else if (error?.response?.status === 404 && redirectIfNotFound) {
        history.replace('/not-found');
    } else {
        message = 'Network error';
        if (customErrorMessage) message = customErrorMessage;
        enqueueSnackbar(message, { variant: 'error' });
    }

    console.error(error);
    return Promise.reject(message);
}

const handler = {
    logout,
    handleError,
};

export default handler;
