/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import cartHelper from '../helpers/cartHelper';
import shippingDataHelper from '../helpers/shippingDataHelper';

const useProvincesAndLocalities = ({ initialLocality, initialProvince }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [localities, setLocalities] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [selectedLocality, setSelectedLocality] = useState(initialLocality);
    const [selectedProvince, setSelectedProvince] = useState(initialProvince);

    const fetchLocalities = async () => {
        if (selectedProvince) {
            setLoading(true);
            try {
                const localitiesData = await shippingDataHelper.getAvailableLocalities(selectedProvince);

                setLocalities(localitiesData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }
    };

    const getProvinces = () => {
        const provincesData = cartHelper.getProvinces();
        setProvinces(provincesData);
    };

    useEffect(getProvinces, []);

    useEffect(() => {
        if (selectedProvince) fetchLocalities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProvince]);

    return {
        error,
        loading,
        localities,
        provinces,
        selectedLocality,
        selectedProvince,
        setLocalities,
        setSelectedLocality,
        setSelectedProvince,
    };
};

export default useProvincesAndLocalities;
