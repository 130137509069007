import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { useUser } from '../../contexts';
import { CartProductTypes, ProductTypes } from '../../helpers/types';
import ProductCard from '../product/ProductCard';

const CartLinkedProductCard = ({ product, multiSelect }) => {
    const [selectedQuantity, setSelectedQuantity] = useState(product.quantity ?? 1);
    const { enqueueSnackbar } = useSnackbar();
    const [isSelected, setSelected] = useState(false);
    const isBuild = product.type === ProductTypes.BUILD;

    const {
        cart: { hasProduct, getItemInCartForProduct, addItemOnCart, updateItemOnCart, removeProductFromCart, getBuildQuantity },
    } = useUser();

    const isOnCart = hasProduct(product);

    useEffect(() => {
        setSelected(hasProduct(product));
        if (hasProduct(product)) {
            setSelectedQuantity(isBuild ? getBuildQuantity(product) ?? 1 : getItemInCartForProduct(product)?.quantity ?? 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, isOnCart, hasProduct, getItemInCartForProduct, getBuildQuantity]);

    const handleChangeQuantity = async (valueToAdd) => {
        let newQuantity = Math.max(valueToAdd + selectedQuantity, 1);
        if (newQuantity > product.stock) {
            enqueueSnackbar(`Disculpa, solo tenemos ${product.stock} unidad/es en stock`, { variant: 'warning' });
            newQuantity = product.stock;
        }
        setSelectedQuantity(newQuantity);
        if (isSelected) {
            // FIXME: Esto es una abominación. Estamos pasando null como cantidad p/ "eliminar uno"
            // Hay que mejorarlo. Además está duplicado el código, en otro lado es casi igual
            if (isBuild && newQuantity < selectedQuantity) newQuantity = null;
            updateItemOnCart({
                ...product,
                price: product.price.special.ARS,
                quantity: newQuantity,
                cartProductType: isBuild ? CartProductTypes.BUILD : CartProductTypes.PRODUCT,
            });
        }
    };

    const handleAddProduct = () => {
        setSelected(true);
        addItemOnCart({
            ...product,
            price: product.price.special.ARS,
            quantity: selectedQuantity,
            cartProductType: isBuild ? CartProductTypes.BUILD : CartProductTypes.PRODUCT,
        });
    };

    const handleRemoveProduct = () => {
        setSelected(false);
        removeProductFromCart(product);
    };

    if (!product) {
        return <h1> Loading </h1>;
    }

    return (
        <ProductCard
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            handleChangeQuantity={handleChangeQuantity}
            isSelected={isSelected}
            multiSelect={multiSelect}
            product={product}
            selectedQuantity={selectedQuantity}
        />
    );
};

export default CartLinkedProductCard;
