import {
    Box,
    Typography, // Button,
    // Link,
    makeStyles,
} from '@material-ui/core';

import colors from '../../theme/colors';
import UserPhotoEdit from './UserPhotoEdit';

const useStyles = makeStyles((theme) => ({
    container: {
        alignSelf: 'flex-start',
        background: colors.black,
        color: colors.white,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: 24,
            marginBottom: 17,
        },
        [theme.breakpoints.up('sm')]: {
            width: 300,
            minWidth: 300,
        },
    },
    userMyProfile: {
        paddingBottom: 10,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // button: {
    //     color: theme.palette.primary.main,
    //     '&:hover': {
    //         color: theme.palette.primary.main,
    //     },
    // },
}));

const UserInfoCard = ({ userPicture, name }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container} p={4} textAlign="center">
            <Typography variant="h3" className={classes.userMyProfile}>
                Mi perfil
            </Typography>
            <UserPhotoEdit userPicture={userPicture} />
            <Box m={2}>
                <Typography variant="h3">{name && name}</Typography>
            </Box>
            {/* <Button
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.button}
                component={Link}
                style={{ textDecoration: 'none' }}
                href={process.env.REACT_APP_COMUNIDAD_URL}
                target="_blank"
            >
                Ir a Comunidad GF
            </Button> */}
        </Box>
    );
};

export default UserInfoCard;
