import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';

import { ProductTypes } from '../../../../helpers/types';
import BaseProductMultiSelect from '../BaseProductMultiSelect';

const countRamsQuantity = (rams) => {
    let quantity = 0;
    rams.forEach((ram) => {
        quantity += ram.quantity * ram.specs.quantity;
    });
    return quantity;
};

const RAMSelect = ({ updateBuildSpecs, buildSpecs, setGoToNextAllowed, setEvenRams }) => {
    const [openAlert, setOpenAlert] = useState(false);

    const validateRams = (rams) => {
        const quantity = countRamsQuantity(rams);
        const motherSupportsSelectedNumberOfRams = buildSpecs.mother.specs.ram_slots >= quantity;
        setOpenAlert(!motherSupportsSelectedNumberOfRams);
        return motherSupportsSelectedNumberOfRams;
    };

    const validateQuantity = ({ quantity }) => {
        if (quantity > buildSpecs.mother.specs.ram_slots) {
            throw new Error(`Con tu selección de componentes podés agregar 
                hasta ${buildSpecs.mother.specs.ram_slots} unidades de RAMs`);
        }
    };

    useEffect(() => {
        const quantity = countRamsQuantity(buildSpecs.ram);
        setEvenRams(quantity % 2 === 0);
    }, [buildSpecs.ram, setEvenRams]);

    const validateNextStepAllowed = () => {
        setGoToNextAllowed(buildSpecs.ram && buildSpecs.ram.length > 0);
    };

    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={5000} onClose={() => setOpenAlert(false)}>
                <Alert severity="error" variant="filled">
                    No puedes seleccionar una cantidad de RAM mayor a la capacidad máxima del Motherboard ({buildSpecs.mother.specs.ram_slots})
                </Alert>
            </Snackbar>
            <BaseProductMultiSelect
                buildProduct={buildSpecs.ram}
                validateProducts={validateRams}
                buildSpecs={buildSpecs}
                updateBuildSpecs={updateBuildSpecs}
                validateNextStepAllowed={validateNextStepAllowed}
                productType={ProductTypes.RAM}
                validateQuantity={validateQuantity}
            />
        </>
    );
};

export default RAMSelect;
