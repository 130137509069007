import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useContext } from 'react';

import { UserContext } from '../../../contexts';
import cartHelper from '../../../helpers/cartHelper';
import useStyles from './useStyles';

const ProductsTotal = ({ showDetails, setShowDetails, showDetailsButton }) => {
    const classes = useStyles();
    const [state] = useContext(UserContext);

    const handleShowDetails = (e) => {
        e.preventDefault();
        setShowDetails(!showDetails);
    };

    return (
        <Box mt={4} mb={2}>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <Typography variant="h6" className={classes.products}>
                        Productos
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" className={classes.products}>
                        {cartHelper.calculateTotalItems(state.user?.cart?.items || [])}
                    </Typography>
                </Grid>
                {showDetailsButton && (
                    <Grid item className={classes.details} xs={12}>
                        <Box display="flex">
                            <Button className={classes.detailsButton} onClick={handleShowDetails} endIcon={showDetails ? <ArrowDropUp /> : <ArrowDropDown />}>
                                VER DETALLE
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ProductsTotal;
