import { useMemo } from 'react';
import GoogleButton from 'react-google-button';

const checkIfIsWebView = () => {
    const { standalone } = window.navigator;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    if (ios) return !standalone && !safari;
    return userAgent.includes('wv');
};

function GoogleLoginButton(props) {
    const isWebView = useMemo(() => checkIfIsWebView(), []);
    const { onClick } = props;

    if (isWebView !== false) return null; // Don't show the button if it's a webview
    return (
        <GoogleButton
            type="light"
            label="Continuar con Google"
            onClick={onClick}
            style={{
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                margin: '16px 0',
                border: 0,
                fontFamily: 'Frutiger',
                width: '302px',
                padding: '0px 47px 20px 0px',
            }}
            {...props}
        />
    );
}

export default GoogleLoginButton;
