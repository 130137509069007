const widths = [
    { id: 120, name: '120mm' },
    { id: 140, name: '140mm' },
];

const connections = [
    { id: '3-pin', name: '3-Pin' },
    { id: '4-pin', name: '4-Pin' },
];

const lightings = [
    { id: 'argb', name: 'ARGB' },
    { id: 'rgb', name: 'RGB' },
    { id: 'fixedColor', name: 'Color Fijo' },
    { id: 'none', name: 'Sin iluminación' },
];

export default {
    width: widths,
    connection: connections,
    lighting: lightings,
};
