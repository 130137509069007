import boolean from './boolean';

export const types = [
    { id: 'internal', name: 'Internal' },
    { id: 'external', name: 'External' },
];

export const connections = [
    { id: 'M2', name: 'M2' },
    { id: 'SATA', name: 'SATA' },
    { id: 'USB', name: 'USB' },
]

export const protocols = [
    { id: 'SATA3', name: 'SATA 3' },
    { id: 'NVME', name: 'NVMe' },
];

export const technologies = [
    { id: 'SSD', name: 'SSD' },
    { id: 'HDD', name: 'HDD' },
];

export default {
    type: types,
    connection: connections,
    protocol: protocols,
    technology: technologies,
    notebookCompatible: boolean,
};
