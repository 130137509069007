import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    orTypography: {
        fontWeight: 500,
        color: '#DBDBD5',
    },
}));

function CustomDivider() {
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Divider variant="middle" display="inline" style={{ width: '42%' }} />
            <Typography variant="subtitle1" className={classes.orTypography} display="inline">
                {' '}
                O{' '}
            </Typography>
            <Divider variant="middle" display="inline" style={{ width: '42%' }} />
        </Box>
    );
}

export default CustomDivider;
